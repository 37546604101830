import { useEffect,useState, useContext } from "react";
import { Banner } from "../common";
import { getCms } from "../classes/cmsApi";
import { useTranslation } from 'react-i18next';
import { StateContext } from "../context";

const BUJ = () => {
  const [sectionData, setSectionData] = useState(null);
  const { language } = useContext(StateContext);
  const { t } = useTranslation();
  
  const bannerData = {
    title: t("FAQ"),
    path1: t("workspaceStart"),
    path2: t("AboutThePlatform"),
    path3: t("QA"),
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const textSectionData = await getCms(`api/v1/content/${language}/buj`);
        setSectionData(textSectionData);
      } catch (error) {
        // Handle thine error here
      }
    };
  
    fetchData();
  }, []);


  return (
    <>
      <Banner bannerData={bannerData} />
      <div className="content"></div>
      <div className="content-main">
        <div className="content-main-left">
          <div className="content-main-left-width">
            {sectionData.body[0].value}
            {/* <Item
              key={1}
              item={sectionData.body[0].value}
              open={1 === (open == null ? lowest : open)}
              onClick={() => setOpen(open === 1 ? 0 : 1)}
            /> */}
          </div>
        </div>
        <div className="content-main-right">
          <div>
            <img alt="alt" src="/assets/img/content.png" />
          </div>
        </div>
      </div>
    </>
  );
};

const Item = ({ item, open, onClick }) => {
  return (
    <>
      <div className="content-main-left-width-container" onClick={onClick}>
        <img
          alt="alt"
          src={`/assets/img/${!open ? "plus" : "minus"}.svg`}
          style={{ height: 20, width: 21 }}
        />
        <p className="content-main-left-width-font5" style={{ marginLeft: 32 }}>
          <b>{item.title}</b>
        </p>
      </div>

      {open && (
        <div className={`content-main-left-width-font4 item ${open ? "expanded" : "collapsed"}`}>
          {item.text}
          Ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
          deserunt mollit anim id est laborum.
        </div>
      )}
    </>
  );
};

export default BUJ;

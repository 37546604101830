const Field = ({ title, text, url, ...props }) => {
  return (
    <div className="box-field" {...props}>
      {title && <span style={{ marginRight: 10 }}> {title} </span>}
      {url ? (
        <span href={url} className="box-url" style={{ fontWeight: 600, color: "lightblue" }}>
          {url}
        </span>
      ) : (
        <p className="box-field box-field-value" style={{ fontWeight: 600, margin: 0 }}>
          {text}
        </p>
      )}
    </div>
  );
};

export default Field;

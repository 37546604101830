import { useNavigate } from "react-router-dom";
import Tooltip from "../../../../common/atoms/tooltip";
import DeleteModal from "../../../../modals/deletemodal";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { useContext } from "react";
import { StateContext } from "../../../../context";
import { TableHeadColumn } from "./Table";

const TitleCol = ({ item, colProps, onTitleClick }) => {
  const { language } = useContext(StateContext);

  const handleClick = () => {
    if (onTitleClick) {
      onTitleClick();
    } else navigate("/card/" + item.uuid);
  };
  const navigate = useNavigate();
  return (
    <td className={`abonetas-td-1 ${colProps?.col1.td1Class ?? ""}`}>
      <div style={{ display: "flex" }}>
        {["publish", "deletable"].includes(item.node_status) || item.status === "1" ? (
          <div className="manaskopas-main-tb1-tr2-td2" style={{ marginRight: 0 }}>
            <button className="abonetaskopas-status">
              <span className="abonetaskopas-status-font">{t("active").toUpperCase()}</span>
            </button>
          </div>
        ) : (
          <div className="manaskopas-main-tb2-tr2-td2">
            <button className="manaskopas-main-tb2-tr2-td2-btn">
              <span className="abonetaskopas-status-font">{t("inactive").toUpperCase()}</span>
            </button>
          </div>
        )}
        <div
          className={`manaskopas-main-tb1-tr2-td1 ${colProps?.col1.td1ContentClass}`}
          onClick={handleClick}
        >
          <span
            className="manaskopas-main-tb1-tr2-td1-font"
            style={{ textOverflow: "ellipsis", maxWidth: "23em", overflow: "hidden", marginLeft: 0 }}
          >
            {language === "lv" ? item.node_title_lv || item.term_title : item.node_title || item.term_title}
          </span>
          <img
            alt="alt"
            className={`manaskopas-main-tb1-tr2-td1-warm1 ${colProps?.col1.svgClass} `}
            src={`/assets/img/${colProps?.col1?.svg ?? "link"}.svg`}
          />
        </div>
      </div>
    </td>
  );
};

const IconContainer = ({ icon, onClick }) => {
  return (
    <div onClick={onClick} className="icon-container" style={{ cursor: "pointer" }}>
      <img
        alt="alt"
        style={{ width: "1em", height: "1em", fill: "dbdcdc" }}
        src={`/assets/img/${icon}.svg`}
      />
    </div>
  );
};

const AbonetasKopasTable = ({ data, refetch }) => {
  const navigate = useNavigate();
  const { handleModalOpen } = useContext(StateContext);

  return (
    <>
      <DeleteModal modalTtile={t("unSubscribeLong")} refetch={refetch} buttonTitle={t("unSubscribe")} />

      <div style={{ overflowX: "auto", borderRadius: 4 }}>
        <table className="manaskopas-main-tb1 fixed-table">
          <thead>
            <tr className="manaskopas-main-tb1-tr1">
              <TableHeadColumn colWidth="45%" text={t("dataSet")} thClass="manaskopas-main-tb1-tr1-th1" />
              <TableHeadColumn colWidth="25%" text={t("datasetAPIKey")}>
                <Tooltip
                  text={t("apiAndEndpointTooltip")}
                  customStyle={{ fontWeight: "400" }}
                  onClickKeepOpen={true}
                >
                  <img alt="?" className="gray-question-mark" src="/assets/img/question.svg" />
                </Tooltip>
              </TableHeadColumn>
              <TableHeadColumn colWidth="20%" text={t("datasetMQTTTopic")}>
                <Tooltip
                  text={t("datasetMQTTTopicHelp")}
                  customStyle={{ fontWeight: "400" }}
                  onClickKeepOpen={true}
                >
                  <img alt="?" className="gray-question-mark" src="/assets/img/question.svg" />
                </Tooltip>
              </TableHeadColumn>
              <TableHeadColumn colWidth="10%" /> {/*unsubscribe*/}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              <>
                {data.map((item) => (
                  <AbonetasKopasTableRow
                    item={item}
                    key={item.uuid}
                    col4={
                      <td>
                        <div
                          className="manaskopas-main-tb1-tr2-td3-right-part1 manaskopas-main-tb1-tr2-td3-right-part2-sub2-delete margin-x-2em-auto-table"
                          onClick={() => handleModalOpen("delete", item)}
                          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                          <img
                            alt="alt"
                            className="manaskopas-main-tb1-tr2-td3-right-part2-sub2 "
                            src="/assets/img/bin.svg"
                          />
                          <span
                            className="manaskopas-main-tb1-tr2-td3-right-part2-font2"
                            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                          >
                            <Trans i18nKey="unSubscribe"></Trans>
                          </span>
                        </div>
                      </td>
                    }
                  />
                ))}
              </>
            ) : (
              <td colSpan={3} style={{ textAlign: "center" }} className="dashboard-topic-right-top-text">
                <Trans i18nKey="noSubscribedDatasets" />
                <br />
                <Trans i18nKey="noSubscribedDatasets2" />
                <span className="generic-link" onClick={() => navigate("/catalog")}>
                  <Trans i18nKey="noSubscribedDatasetsLink" />
                </span>
              </td>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const AbonetasKopasTableRow = ({ item, col1, col2, col3, col4, col5, colProps, onTitleClick }) => {
  return (
    <tr
      className={`manaskopas-main-tb1-tr2 ${item?.field_seen_by === "" ? "unread" : ""}`}
      style={{ maxWidth: "50%" }}
    >
      {col1 ?? <TitleCol item={item} colProps={colProps} onTitleClick={onTitleClick} />}
      {col2 ?? (
        <td className={`abonetas-td-2 ${colProps?.col2?.td2Class ?? ""}`}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <div style={{ gap: "2em" }} className="manaskopas-margin-x-2em tooltip-inside-table">
              <Tooltip text={item?.api_key} onClickKeepOpen={true} customStyle={{ top: "4em" }}>
                <div className="api-container" style={{ width: "10em" }}>
                  <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{item?.api_key}</span>
                  <img
                    onClick={() => navigator.clipboard.writeText(item?.api_key)}
                    alt=""
                    style={{
                      width: "1em",
                      height: "1em",
                      fill: "dbdcdc",
                      cursor: "pointer",
                    }}
                    className="modal-dialog-content-sub2-font3-icon"
                    src="/assets/img/file.svg"
                  />
                </div>
              </Tooltip>

              <IconContainer
                onClick={() =>
                  navigator.clipboard.writeText(
                    process.env.REACT_APP_CMS_API + `api/v1/dataset/${item?.id}/download`,
                    "_blank"
                  )
                }
                icon="attach1"
              />
            </div>
          </div>
        </td>
      )}

      {col3 ?? (
        <td>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <div className="tooltip-inside-table">
              <Tooltip text={item?.mqtt_topic} onClickKeepOpen={true} customStyle={{ top: "4em" }}>
                <div
                  className={item.mqtt_topic ? "api-container manaskopas-margin-x-2em" : ""}
                  style={{ width: "10em" }}
                >
                  <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{item?.mqtt_topic}</span>
                  {item?.mqtt_topic && (
                    <img
                      onClick={
                        item?.mqtt_topic ? () => navigator.clipboard.writeText(item?.mqtt_topic) : undefined
                      }
                      alt=""
                      style={{
                        width: "1em",
                        height: "1em",
                        fill: "dbdcdc",
                        cursor: "pointer",
                      }}
                      className="modal-dialog-content-sub2-font3-icon"
                      src="/assets/img/file.svg"
                    />
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
        </td>
      )}
      {col4}
      {col5}
    </tr>
  );
};

export { AbonetasKopasTable, AbonetasKopasTableRow };

import { useTranslation } from "react-i18next";
import NotificationBanner from "../../common/notification";

const ConfirmedEmailPage = () => {
  const { t } = useTranslation();
  return (
    <NotificationBanner
      text={t("emailConfirmed")}
      backgroundColor="#56595a"
      canClose={false}
    />
  )
};

export default ConfirmedEmailPage;
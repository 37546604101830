import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AddBox from "./components/addbox";

import { Banner } from "../../common";
import Loader from "../../common/loader";
import { extractDrupalData } from "../../helpers";
import { ErrorMessage, ReloadPageButton } from "./components/errormessage";
import { getCms } from "../../classes/cmsApi";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const initial = {
  check1: false,
  check2: false,
};

const AddKopa = () => {
  const location = useLocation();
  const copyData = location.state;
  const { t } = useTranslation();

  const { id } = useParams();
  const isNew = id === "new";

  const [showError, setShowError] = useState(false);
  const [checkboxState, setCheckBoxState] = useState(initial);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [extraData, setExtradata] = useState({
    schemas: [],
    declarations: [],
    formData: {},
    isNew: isNew,
    copy: false,
  });

  const bannerData = {
    title: isNew ? t("addDs") : t("editDS"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
    path3: t("publishedDS"),
    path3Url: "/manaskopas/list",
    path4: isNew ? t("addDs") : t("editDS"),
  };

  const getExtraData = useCallback(async () => {
    setLoading(true);
    try {
      const declerations = await getCms("api/v1/get/owner/confirmation/list");
      let endObject = {
        active: [],
      };

      if (declerations) {
        declerations?.forEach((entry) => {
          const { value: statusValue } = entry.status[0];
          entry.status = statusValue;
          const { value: creationTimeValue } = entry.creation_time[0];
          entry.creation_time = creationTimeValue;
          if (entry.status === "1") {
            endObject.active.push(entry);
          }
        });
      }

      if (!isNew) {
        var formData = await getCms(`api/v1/get/own/metadata/` + id);
        setStep(1);
      }

      if (Boolean(copyData)) {
        setCheckBoxState({
          check1: copyData?.formData?.distribution_mode === "file",
          check2: copyData?.formData?.distribution_mode === "api",
        });
      }

      setExtradata({
        declarations: endObject.active,
        formData: Boolean(copyData) ? copyData?.formData : extractDrupalData([formData])[0] ?? {},
        isNew,
        copy: Boolean(copyData),
      });
    } catch (err) {
      setShowError(true);
    }
    setLoading(false);
  }, [isNew, id, copyData]);

  useEffect(() => {
    getExtraData();
  }, [getExtraData]);

  if (loading) return <Loader />;
  if (showError)
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <ErrorMessage text={"Notika kļūda"} />
        <ReloadPageButton style={{ marginTop: 16 }} />
      </div>
    );

  return (
    <>
      <Banner bannerData={bannerData} />
      {isNew ? (
        <Steps step={step}>
          <AddBox
            setStep={setStep}
            step={step}
            onClick={() => setStep(1)}
            extraData={extraData}
            checkboxState={checkboxState}
            setCheckBoxState={setCheckBoxState}
          />
        </Steps>
      ) : !extraData?.formData?.nid ? (
        <div className="authorize-main-left-alert" style={{ marginBottom: 10, marginTop: 10, height: 30 }}>
          <div className="authorize-main-left-alert-text">Šāda datu kopa neeksistē!</div>
        </div>
      ) : (
        <AddBox
          setStep={setStep}
          step={step}
          onClick={() => setStep(1)}
          extraData={extraData}
          checkboxState={checkboxState}
          setCheckBoxState={setCheckBoxState}
        />
      )}
    </>
  );
};

const Steps = ({ step, children }) => {
  return (
    <div className="register">
      <div className="register-info w3-row">
        <div className="register-info-left ">
          <div className="register-info-left-steps">
            <div className="register-info-left-steps-item">
              <div id={`progress-bar`}></div>
              <div className="register-info-left-steps-item">
                <div className="register-info-left-steps-item-icon-circle active">
                  <span style={{ marginLeft: 3 }}> 1 </span>
                </div>
              </div>
              <div className="register-info-left-steps-item-label">
                <span>
                  <b>
                    <Trans i18nKey="datasetAddPublicationType"></Trans>
                  </b>
                </span>
                <br />
                <Trans i18nKey="datasetAddToAddInfo"></Trans>
              </div>
            </div>
          </div>

          <div className="register-info-left-steps-item">
            <div className={`register-info-left-steps-item-icon-circle${step > 0 ? " active" : ""}`}>
              <div id={`progress-bar${step === 0 ? "-gray" : ""}`} style={{ zIndex: -2 }}></div>
              <span>2</span>
            </div>
            <div className="register-info-left-steps-item-label">
              <span>
                <b className="steps-label-header">
                  <Trans i18nKey="datasetAddData"></Trans>
                </b>
              </span>
              <br />
              <span className="steps-label-description">
                <Trans i18nKey="datasetAddInputData"></Trans>
              </span>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default AddKopa;

import React, { useCallback, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import PrimaryButton from "../../../common/atoms/primarybutton";
import { t } from "i18next";
import Tooltip from "../../../common/atoms/tooltip";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import moment from "moment";

const customLocale = {
  months: [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ],
  weekdaysShort: [
    t("sunShort"),
    t("monShort"),
    t("tueShort"),
    t("wedShort"),
    t("thuShort"),
    t("friShort"),
    t("satShort"),
  ],
  weekdaysMin: [t("sunMin"), t("monMin"), t("tueMin"), t("wedMin"), t("thuMin"), t("friMin"), t("satMin")],
  weekdays: [
    t("sunday"),
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
  ],

  week: {
    dow: 1, // Monday is the first day of the week.
  },
  formats: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD.MM.YYYY",
    LL: "D. MMMM YYYY",
    LLL: "D. MMMM YYYY HH:mm",
    LLLL: "dddd, D. MMMM YYYY HH:mm",
  },
};

moment.updateLocale("custom", customLocale);

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div
        style={{
          display: "flex",
          width: 20,
        }}
      >
        <img
          alt="dropdown arrow"
          src="/assets/img/arrow.svg"
          className="dropdown-arrow"
          style={{
            marginTop: -20,
          }}
        />
      </div>
    </components.DropdownIndicator>
  );
};

function MultipleDropdown({
  fieldName,
  defval = [],
  placeholder,
  register,
  errors,
  getValues = () => ({}),
  getFieldState = () => ({}),
  onChange = () => {},
  onBeforeChange,
  rules,
  options = [],
  title,
  tooltipText = "",
  setValue,
  resetOptions,
  disabled,
  ...props
}) {
  const [selectedOptions, setSelectedOptions] = useState(defval);
  const prevSelectedOptions = useRef(defval);
  const init = useRef(true);

  useEffect(() => {
    register(fieldName, {
      ...rules,
      value: selectedOptions,
    });
  }, [fieldName, register, rules, selectedOptions]);

  useEffect(() => {
    setValue(fieldName, selectedOptions);
    if (prevSelectedOptions.current !== selectedOptions) {
      prevSelectedOptions.current = selectedOptions;
      onChange(selectedOptions);
    }
  }, [selectedOptions, setValue, fieldName, onChange]);

  useEffect(() => {
    if (init.current) {
      init.current = false;
      return;
    }
    setSelectedOptions([]);
  }, [resetOptions]);

  const handleOptionSelect = useCallback(
    (option) => {
      setSelectedOptions((prev) => {
        const newValue = prev.includes(option) ? prev.filter((val) => val !== option) : [...prev, option];
        setValue(fieldName, newValue);
        register(fieldName, rules)?.onChange?.(newValue.length > 0 ? "ok" : undefined);
        return newValue;
      });
    },
    [fieldName, register, rules, setValue]
  );

  const getOptionValue = useCallback(
    (key) => {
      const foundOption = options.find((option) => `${option.key}` === `${key}`);
      return foundOption ? foundOption.value : key;
    },
    [options]
  );

  const hasError = errors?.[fieldName];

  return (
    <>
      <div {...props} className="input-component">
        {title && <div className="register-info-right-profile-info-input-title">{title}</div>}

        <div
          style={{
            display: "flex",
            gap: "1em",
            alignItems: "center",
            ...(!title && { gridColumnEnd: "span 2" }),
          }}
        >
          <div className="input-wrapper">
            <Select
              isMulti
              isSearchable
              components={{ DropdownIndicator }}
              defaultValue={selectedOptions.map((key) => {
                const option = options.find((opt) => `${opt.key}` === `${key}`);
                return option ? { key: option.key, value: option.value } : { key, value: key };
              })}
              value={selectedOptions.map((key) => {
                const option = options.find((opt) => `${opt.key}` === `${key}`);
                return option ? { key: option.key, value: option.value } : { key, value: key };
              })}
              controlShouldRenderValue={false}
              onChange={(selected) => {
                if (onBeforeChange) {
                  if (onBeforeChange(selected.map((item) => item.key))) {
                    return;
                  }
                }
                const newValue = selected ? selected.map((item) => item.key) : [];
                setSelectedOptions(newValue);
                register(fieldName, rules)?.onChange?.(newValue.length > 0 ? "ok" : undefined);
              }}
              options={options}
              noOptionsMessage={() => t("noOptions")}
              isOptionDisabled={(option) => selectedOptions.includes(option.key)}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.key}
              isDisabled={disabled}
              placeholder={placeholder}
              styles={{
                control: (base, state) => ({
                  ...base,
                  border: "2px solid var(--c_input_border)",
                  backgroundColor: "var(--background-color)",
                  borderRadius: "5px",
                  boxShadow: "none",
                  outline: state.isFocused
                    ? "4px solid var(--c_light_blue)"
                    : hasError
                    ? "2px solid var(--c_error)"
                    : "none",
                  "&:hover": {
                    outline: "4px solid var(--c_light_blue)",
                    opacity: 0.8,
                  },
                }),
                menuList: (base) => ({
                  ...base,
                  backgroundColor: "var(--background-color)",
                }),
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  background:
                    isFocused && isSelected
                      ? "var(--c_input_option_focused_selected)"
                      : isFocused
                      ? "var(--c_input_option_focused)"
                      : isSelected
                      ? "var(--c_input_option_selected)"
                      : "",
                  color: isSelected ? "white" : "black",
                  zIndex: 1,
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: "#F5F5F5",
                  borderRadius: 4,
                  padding: "2px 4px",
                }),
              }}
              classNamePrefix={
                hasError && !selectedOptions.length
                  ? "overall-input-dropdown-error"
                  : "overall-input-dropdown"
              }
              className={selectedOptions.length ? "" : "default-option"}
            />
          </div>
          {(selectedOptions.length > 0 || getFieldState(fieldName).isTouched) && !hasError ? (
            <img
              alt="checkmark"
              src="/assets/img/check.svg"
              style={{ width: 15, height: 15 }}
              className="checkmark"
            />
          ) : (
            <div style={{ width: 15, height: 15, display: "flex", justifyContent: "center" }}>
              {(errors[fieldName]?.message || tooltipText) && (
                <Tooltip text={`${hasError ? t(errors[fieldName]?.message) : ""}${tooltipText}`}>
                  <img
                    alt="question mark"
                    src="/assets/img/question.svg"
                    className={`tooltip-img ${hasError ? "red-filter" : ""}`}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
      {selectedOptions.length ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5em",
            ...(!title && { gridColumnEnd: "span 2" }),
          }}
        >
          {selectedOptions.map((key) => (
            <label key={key} className="label">
              <p className="font">{getOptionValue(key)}</p>
              <img
                alt="remove option"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionSelect(key);
                }}
                className="icon-close"
                src="/assets/img/close.svg"
              />
            </label>
          ))}
        </div>
      ) : null}
    </>
  );
}

const DataBox = ({ children, label, ...props }) => {
  if (!children) return;

  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr 2fr",
        gap: "2em",
      }}
    >
      {label && (
        <div className="overall-databox-label">
          <span>{label}</span>
        </div>
      )}
      <div className="overall-databox" {...props} style={{ ...(!label && { gridColumnEnd: "span 2" }) }}>
        {children.map((child, index) => {
          if (!child) return null; // If child is not present, return null

          const isLastChild = index === children.length - 1;

          return (
            <React.Fragment key={index}>
              {child}
              {!isLastChild && <div className="overall-databox-devider" />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const Input = ({
  name,
  rules = {},
  placeholder = "Obligāts lauks",
  styles = {
    ok: "register-info-right-profile-info-input",
    nok: "register-info-right-profile-info-input register-info-right-profile-info-input-error",
  },
  title = "",
  register,
  errors = {},
  setValue = () => {},
  getValues = () => ({}),
  getFieldState = () => ({}),
  big = false,
  disabled,
  maxLength = null,
  tooltipText = "",
  isMail = false,
  isDate = false,
  minDate = null,
  isPassword = false,
  onChange = () => {},
  customInputStyles = {},
  ...props
}) => {
  const hasError = errors && typeof errors === `object` && name in errors;

  const [dateValue, setDateValue] = useState(
    getValues(name) ? moment(getValues(name), "DD.MM.YYYY HH:mm").toDate() : null
  );

  const handleChange = (e) => {
    if (!e || !e.target) return;
    setValue(name, e.target.value);
    onChange(e);
  };

  const handleChangeDate = (date) => {
    const momentDate = moment(date);

    if (momentDate.isValid()) {
      setValue(name, momentDate.format("DD.MM.YYYY HH:mm"));
      setDateValue(momentDate.toDate());
      onChange(momentDate.format("DD.MM.YYYY HH:mm"));
    }
  };

  return (
    <div
      className={`input-component ${
        name in errors
          ? styles.nok ?? "register-info-right-profile-info-input-error"
          : styles.ok ?? "register-info-right-profile-info-input"
      }`}
      style={customInputStyles}
    >
      {title && <div className="register-info-right-profile-info-input-title">{title}</div>}
      <div
        style={{
          display: "flex",
          gap: "1em",
          alignItems: "center",
          ...(!title && { gridColumnEnd: "span 2" }),
          ...(isMail && { position: "relative" }),
        }}
      >
        {isDate ? (
          <div className="input-wrapper">
            <Datetime
              value={dateValue}
              {...register(name, {
                ...rules,
                onChange: handleChangeDate,
              })}
              onChange={handleChangeDate}
              inputProps={{ disabled }}
              locale="lv"
              dateFormat="DD.MM.YYYY"
              timeFormat="HH:mm"
              isValidDate={(current) => {
                if (minDate) {
                  return current.isAfter(moment(minDate, "DD.MM.YYYY HH:mm"));
                }
              }}
            />
          </div>
        ) : big ? (
          <textarea disabled={disabled} {...register(name, { ...rules })} placeholder={placeholder} />
        ) : (
          <input
            disabled={disabled}
            {...register(name, {
              ...rules,
              onChange: handleChange,
            })}
            placeholder={placeholder}
            maxLength={maxLength}
            style={{ ...(isMail && { paddingLeft: "2.5em" }) }}
            {...(isPassword && { type: "password" })}
          />
        )}
        {isMail && <img alt="alt" src="/assets/img/asperand.svg" className="asperand" />}
        {(getValues(name)?.length > 0 || getFieldState(name).isTouched) && !hasError ? (
          <img
            alt="alt"
            src="/assets/img/check.svg"
            style={{
              width: 15,
              height: 15,
            }}
            className="checkmark"
          />
        ) : (
          <div
            style={{
              width: 15,
              height: 15,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {((hasError && errors[name]?.message) || (!hasError && tooltipText)) && (
              <Tooltip text={`${hasError ? t(errors[name]?.message) : ""}${tooltipText}`}>
                <img
                  alt="alt"
                  src="/assets/img/question.svg"
                  className={`tooltip-img ${hasError ? "red-filter" : ""}`}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>

      {/* {hasError && <p className="error-message">{errors[name]?.message}</p>} */}
    </div>
  );
};

const BigInput = ({ placeholder, svg }) => {
  return <textarea aria-disabled={true} className="overall-input-big" placeholder={placeholder} />;
};

const UploadPicture = ({
  title,
  onClick,
  customText = "",
  customTextClassName = "",
  customTextOnClick = () => {},
  texts = [t("datasetFileSize")],
  showButton = true,
}) => {
  return (
    <>
      {showButton && (
        <PrimaryButton
          id="file-upload-button"
          onClick={onClick}
          svg="upload"
          title={title}
          imgClass={"overall-image-upload-svg"}
        />
      )}
      {
        <span className={`overall-file-upload-text ${customTextClassName}`} onClick={customTextOnClick}>
          {t(customText)}
        </span>
      }
      {texts.map((text, index) => (
        <span key={index} className="overall-file-upload-text">
          {text}
        </span>
      ))}
    </>
  );
};

function Dropdown({
  fieldName,
  defval = [],
  placeholder,
  register,
  errors,
  setValue = () => {},
  getValues = () => ({}),
  getFieldState = () => ({}),
  rules,
  options = [],
  title,
  tooltipText = "",
  setSelectedCategory,
  resetOptions,
  onChange,
  onBeforeChange, // If returns true, then the change is not made
  disabled = false,
  ...props
}) {
  const hasError = errors && typeof errors === `object` && fieldName in errors;
  const [selectedOption, setSelectedOption] = useState(defval ?? "");
  const prevSelectedOption = useRef(defval ?? "");
  useEffect(() => {
    if (prevSelectedOption.current !== selectedOption) {
      prevSelectedOption.current = selectedOption;
      onChange?.(selectedOption);
    }
    getFieldState(fieldName).isTouched && register(fieldName, rules)?.onChange?.(selectedOption);
  }, [selectedOption, onChange, getValues, fieldName, getFieldState, register, rules]);

  useEffect(() => {
    setValue(fieldName, selectedOption, { shouldDirty: true });
  }, [selectedOption, setValue, fieldName]);

  return (
    <div {...props} className="input-component">
      {title && <div className="register-info-right-profile-info-input-title">{title}</div>}
      <div
        style={{
          display: "flex",
          gap: "1em",
          alignItems: "center",
          ...(!title && { gridColumnEnd: "span 2" }),
        }}
      >
        <div className="input-wrapper">
          <Select
            noOptionsMessage={() => t("noOptions")}
            defaultValue={() => options.find((option) => option.key.toString() === defval?.toString())}
            components={{ DropdownIndicator }}
            {...register(fieldName, rules)}
            isDisabled={disabled}
            options={options}
            value={options.find((option) => option.key === selectedOption)}
            onChange={(selected) => {
              if (onBeforeChange?.(selected.key)) {
                return;
              }
              setSelectedOption(selected.key);
              setSelectedCategory?.(selected.key);
              register(fieldName, rules)?.onChange?.(selected);
            }}
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.key}
            placeholder={placeholder}
            styles={{
              control: (base, state) => ({
                ...base,
                border: "2px solid var(--c_input_border)",
                backgroundColor: "var(--background-color)",
                borderRadius: "5px",
                boxShadow: "none",
                outline: state.isFocused
                  ? "4px solid var(--c_light_blue)"
                  : hasError
                  ? "2px solid var(--c_error)"
                  : "none",
                "&:hover": {
                  outline: "4px solid var(--c_light_blue)",
                  opacity: 0.8,
                },
              }),
              menuList: (base) => ({
                ...base,
                backgroundColor: "var(--background-color)",
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                background:
                  isFocused && isSelected
                    ? "var(--c_input_option_focused_selected)"
                    : isFocused
                    ? "var(--c_input_option_focused)"
                    : isSelected
                    ? "var(--c_input_option_selected)"
                    : "",
                color: isSelected ? "white" : "black",
                zIndex: 1,
              }),
            }}

            // className={`${
            //   errors && errors[fieldName] && !selectedOption.length
            //     ? "overall-input-dropdown-error"
            //     : "overall-input-dropdown"
            // }`}
          />
        </div>

        {(getValues(fieldName)?.length > 0 || getFieldState(fieldName).isTouched) && !hasError ? (
          <img
            alt="alt"
            src="/assets/img/check.svg"
            className="checkmark"
            style={{
              width: 15,
              height: 15,
            }}
          />
        ) : (
          <div
            style={{
              width: 15,
              height: 15,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {errors[fieldName]?.message && (
              <Tooltip text={`${hasError ? t(errors[fieldName]?.message) : ""}${tooltipText}`}>
                <img
                  alt="alt"
                  src="/assets/img/question.svg"
                  className={`tooltip-img ${hasError ? "red-filter" : ""}`}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
      {/* {errors && errors[fieldName] && <p className="error-message">{errors[fieldName]?.message}</p>} */}
    </div>
  );
}

const BetterDropdown = ({
  name = "",
  placeholder = "",
  register,
  errors,
  rules = {},
  options = [],
  title = "",
  getValues,
  ...props
}) => {
  return (
    <div {...props}>
      {title && <span className="steps-label-description steps-label-description-span">{title}</span>}
      <div>
        <select
          {...register(name, rules)}
          className={errors?.[name] ? "overall-input-dropdown-error" : "overall-input-dropdown"}
          style={{ color: getValues(name) ? "black" : "#545454", backgroundColor: "white" }}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option
              key={option.key}
              value={option.key}
              style={{
                color: "#717273",
              }}
            >
              {option.value}
            </option>
          ))}
        </select>
        <img alt="alt" src="/assets/img/arrow.svg" className="dropdown-arrow" />
        {errors?.[name] && <p className="error-message">{t(errors[name]?.message)}</p>}
      </div>
    </div>
  );
};

export { MultipleDropdown, UploadPicture, Input, DataBox, BigInput, Dropdown, BetterDropdown };

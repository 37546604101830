import { useContext, useState } from "react";
import { AuthBox, Banner, NotificationBanner } from "../../common";
import Error from "../errorpage/errorpage";
import Box from "./components/box";
import { useTranslation } from "react-i18next";
import { usr as user } from "../../classes/user";
import { StateContext } from "../../context";

const Profile = () => {
  const { t } = useTranslation();
  const bannerData = {
    title: t("workspaceMyProfile"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
    img: {
      href: "/assets/img/user.svg",
      className: "dashboard-topic-left-header-icon",
    },
  };
  const [notifications, setNotifications] = useState([]);

  useContext(StateContext); // must stay to make page rerender on user object change

  if (!user.sub) {
    return <Error code="401" message="Lai piekļūtu šai lapai lūdzu autorizējies!" />;
  }

  return (
    <>
      <Banner bannerData={bannerData} children={<AuthBox />} />
      {notifications.map((notif) => (
        <NotificationBanner {...notif} />
      ))}
      <Box setNotifications={setNotifications} />
    </>
  );
};

export default Profile;

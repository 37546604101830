import { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Banner } from "../common";
import { useTranslation } from "react-i18next";
import { StateContext } from "../context";
import Loader from "../common/loader";
import ReportModal from "../modals/reportmodal";
import { auth } from "../classes/auth";
import { usr as user } from "../classes/user";
import { sanitize } from "dompurify";
import Error from "./errorpage/errorpage";


const GenericDrupalPage = () => {
  const { language, handleModalOpen } = useContext(StateContext);
  const { t } = useTranslation();
  let { lang, uri, uri2 } = useParams();
  if (uri2) uri = uri + "/" + uri2;
  let bannerData = "";
  const [sectionData, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  bannerData = {
    title: sectionData?.title?.[0]?.value,
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: sectionData?.title?.[0]?.value,
  };


  switch (uri) {
    case "buj":
      bannerData = {
        title: t("FAQ"),
        path1: t("workspaceStart"),
        path1Url: "/",
        path2: t("AboutThePlatform"),
        path3: t("QA"),
      };
      break;
    case "faq":
      bannerData = {
        title: t("FAQ"),
        path1: t("workspaceStart"),
        path1Url: "/",
        path2: t("AboutThePlatform"),
        path3: t("QA"),
      };
      break;
    case "kontakti":
      bannerData = {
        title: t("contacts"),
        path1: t("workspaceStart"),
        path1Url: "/",
        path2: t("contacts"),
      };
      break;
    // case "about":
    //   bannerData = {
    //     title: t("about"),
    //     // path1: t("workspaceStart"),
    //     // path1Url: "/",
    //     // path2: t("about"),
    //   };
    //   break;
    case "privatumapolitika":
      bannerData = {
        title: t("privacyPolicy"),
        path1: t("workspaceStart"),
        path1Url: "/",
        path2: t("privacyPolicy"),
      };
      break;
    case "statistika":
      bannerData = {
        title: t("statistic"),
        path1: t("workspaceStart"),
        path1Url: "/",
        path2: t("statistic"),
      };
      break;
    case "klusti":
      bannerData = {
        title: t("becomeAPartner"),
        path1: t("workspaceStart"),
        path1Url: "/",
        path2: t("becomeAPartner"),
      };
      break;
    case "lietosanasnoteikumi":
      bannerData = {
        title: t("termsOfUse"),
        path1: t("workspaceStart"),
        path1Url: "/",
        path2: t("termsOfUse"),
      };
      break;
    case "vieglilasit":
      bannerData = {
        title: t("pathingEasyRead"),
        path1: t("workspaceStart"),
        path1Url: "/",
        path2: t("pathingEasyRead"),
      };
      break;
    default:
      // Code to run if no case is matched
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await fetch(`/api/v1/content/${language}/${uri}?_format=json`);
        if (response.ok) {
        const textSectionData = await response.json();
        setData(textSectionData);
        setHasError(false);
        } else {
          setHasError(true);
        }
      } catch (error) {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lang, uri, uri2, language]);

  useEffect(() => {
    if (lang === "messages" && !auth.isAuthorized()) {
      handleModalOpen("auth", { redirectTo: lang + "/"+ uri});
    }
  }, [hasError, lang, uri]); // do NOT add handleModalOpen even if vsc recomends
  

  const popupClickHandler = useCallback(() => {
    handleModalOpen("report")
  });

  useEffect(()=>{
    const element = document.querySelector('.report-error-or-message-popup ');
    if (!element) return;
    if (!auth.isAuthorized()) {
        element.remove();
        return;
    }
    element.removeEventListener('click', popupClickHandler);
    if (user?.roles && user.roles.length) {
        element.style.display='inline';
        element.addEventListener('click', popupClickHandler);
    } else {
        element.style.display='none';
    }
    return () => element.removeEventListener('click', popupClickHandler);
}, [sectionData]);


  const objectWIthHtml = sectionData?.body?.[0]?.value
  
  return (
    <>
      {hasError ? <Error /> :
      <>
      <Banner bannerData={bannerData} />
      <div className="content"></div>
      <div className="content-main">
        <div className="content-main-left">
          <div className={uri === "klusti" ? "" : "content-main-left-width"}>
            {uri === "buj" ? (
              <DivArrayComponent htmlString={objectWIthHtml} />
            ) : sectionData === null ? (
              <Loader />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: objectWIthHtml || "" }} />
            )}
          </div>
        </div>
        <div className="content-main-right">
          <div>
            {sectionData !== null &&
            <img alt="alt" src={`/assets/img/${uri === "klusti" ? "content-left.png" : "content.png"}`} />}
          </div>
        </div>
        <ReportModal />
      </div>
      </>
      }
    </>
  );
};

export default GenericDrupalPage;

function DivArrayComponent({ htmlString }) {
  const [activeElementIndexes, setActiveElementIndexes] = useState([]);

  // Step 1: Parse the HTML string to create a DOM object
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Step 2: Extract <div> elements
  const divs = doc.querySelectorAll("div");

  // Step 3: Convert NodeList to Array
  const divArray = Array.from(divs);

  // Step 4: Pair every two divs
  const pairedDivs = [];
  for (let i = 0; i < divArray.length; i += 2) {
    const pair = divArray.slice(i, i + 2);
    pairedDivs.push(pair);
  }

  // Step 5: Render in React
  return (
    <div>
      {pairedDivs.map((pair, index) => {
        const [trigger, content] = pair;
        if (!trigger || !content) return null;
        return (
          // Wrap each pair in a new div
          <div key={index}>
            {/* Trigger */}
            <div
              onClick={() => setActiveElementIndexes((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]))}
              style={{ display: "flex", alignItems: "center", cursor: "pointer", userSelect: "none" }}
            >
              <img
                width="20"
                height="20"
                alt="expand"
                // src={`/assets/img/${activeElementIndex. index ? "minus" : "plus"}.svg`}
                src={`/assets/img/${activeElementIndexes.includes(index) ? "minus" : "plus"}.svg`}
              />
              <div dangerouslySetInnerHTML={{ __html: sanitize(trigger.outerHTML) }} />
            </div>
            {/* Content */}
            <div
              dangerouslySetInnerHTML={{ __html: sanitize(content.outerHTML) }}
              style={{ display: activeElementIndexes.includes(index) ? "block" : "none" }}
            />
          </div>
        );
      })}
    </div>
  );
}

export const AccordItem = ({ item, tabOpened, helpOpened, setHelpOpened, onMenuItemClick }) => {
  return (
    <>
      <div className="palidzibas-main-accordion-title menu-item active" onClick={onMenuItemClick}>
        <img alt="" className="palidzibas-main-accordion-title-plus" src="/assets/img/plus.svg" />
        <img alt="" className="palidzibas-main-accordion-title-minus" src="/assets/img/minus.svg" />
        <span>{item.title}</span>
      </div>
      {item.id === tabOpened && (
        <Description setHelpOpened={setHelpOpened} helpOpened={helpOpened} data={item.data} />
      )}
    </>
  );
};

const Description = ({ setHelpOpened, helpOpened, data }) => {
  return (
    <div className="palidzibas-main-accordion-description">
      {data?.map((item) => (
        <div key={item.nid}>
          <div
            className={`palidzibas-main-accordion-description-subtitle submenu-item active ${
              helpOpened === item?.nid ? "selected" : ""
            } ${item?.sub.length === 0 ? "infertile" : ""}`}
            onClick={() => {
              if (item?.sub.length === 0) setHelpOpened(item.nid);
            }}
          >
            <img alt="alt" src="/assets/img/list-icon.svg" />
            <span className="palidzibas-main-accordion-description-subtitle-font">{item.title}</span>
          </div>
          {item?.sub?.map((subitem) => (
            <div
              className={`palidzibas-main-accordion-description-subdescription ${
                helpOpened === subitem?.nid ? "selected" : ""
              }`}
              key={subitem.nid}
              onClick={() => {
                setHelpOpened(subitem.nid);
              }}
            >
              <img
                alt="alt"
                className="palidzibas-main-accordion-description-subdescription-icon"
                src="/assets/img/enter.svg"
              />
              <span className="palidzibas-main-accordion-description-subdescription-font">
                {subitem.title}
              </span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

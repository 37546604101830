import { useContext } from "react";
import { StateContext } from "../../../context";
import { Trans } from 'react-i18next';
import { useLocation } from "react-router-dom";


const isTouchDevice = () => {
  return ('ontouchstart' in window) || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

const ContrastItems = () => {
  const { contrastDefinition, contrast, setContrast } = useContext(StateContext);

  return (
    <li>
      <b>
        <Trans i18nKey="contrast" />
      </b>

      <div className="env-dropdown-menu-top">
        {Object.keys(contrastDefinition)
          .slice(0, 2)
          .map((item, index) => (
            <button className={`label${index + 1}`} onClick={() => setContrast(item)} key={index}>
              <img alt="" className="eye-icon" src="/assets/img/eye.svg" />
              {item === contrast && <img alt="" className="tick-icon" src="/assets/img/check1.svg" />}
            </button>
          ))}
      </div>

      <div className="env-dropdown-menu-down">
        {Object.keys(contrastDefinition)
          .slice(2)
          .map((item, index) => (
            <button className={`label${3 - index}`} onClick={() => setContrast(item)} key={index}>
              <img alt="" className={`eye-icon${index + 1}`} src="/assets/img/eye.svg" />
              {item === contrast && <img alt="" className="tick-icon" src="/assets/img/check1.svg" />}
            </button>
          ))}
      </div>
    </li>
  );
};

const Dropdowns = () => {
  const {
    language,
    changeLanguage,
    languageDefinition,
    fontSize,
    setFontSize,
    fontDefinition,
  } = useContext(StateContext);

  const location = useLocation();

  function toggleDropdown() {
    const dropdown = document.querySelector(".env-dropdown");
    dropdown.classList.toggle("open");
  }
  function closeDropdown() {
    const dropdown = document.querySelector(".env-dropdown");
    dropdown.classList.remove("open");
  }
  function toggleLangDropdown() {
    const dropdown = document.querySelector(".lang-dropdown");
    dropdown.classList.toggle("open");
  }
  function closeLangDropdown() {
    const dropdown = document.querySelector(".lang-dropdown");
    dropdown.classList.toggle("open");
  }

  const handleLangDropdownInteraction = (event) => {
    if (isTouchDevice()) {
      event.preventDefault();
      toggleLangDropdown();
    }
  };
  const handleDropdownInteraction = (event) => {
    if (isTouchDevice()) {
      event.preventDefault();
      toggleDropdown();
    }
  }

  return (
    <div className="header-setting-position">
      <div className="lang-container"
           onClick={isTouchDevice() ? handleLangDropdownInteraction : undefined}
           onMouseEnter={!isTouchDevice() ? toggleLangDropdown : undefined}
           onMouseLeave={!isTouchDevice() ? closeLangDropdown : undefined}>
        <div className="lang-dropdown">
          <button className="lang-dropdown-btn">
            <span className="lang-dropdown-value">
              <i className="icon" aria-hidden="true"></i>
              {languageDefinition[language].label ?? "Kļūda"}
            </span>
          </button>
          <ul className="lang-dropdown-menu">
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (language === "en") {
                  changeLanguage("lv", location.pathname);
                } else {
                  changeLanguage("en", location.pathname);
                }
              }}
            >
              {language === "en" ? <Trans i18nKey="langLat"></Trans> : <Trans i18nKey="langEn"></Trans>}
            </li>
          </ul>
        </div>
      </div>

      <div className="env-container">
        <div 
          className="env-dropdown" 
          onClick={isTouchDevice() ? handleDropdownInteraction : undefined}
          onMouseEnter={!isTouchDevice() ? toggleDropdown : undefined}
          onMouseLeave={!isTouchDevice() ? closeDropdown : undefined}
        >
          <button className="env-dropdown-btn">
            <span className="env-dropdown-value">
              <i className="icon"></i>
              <Trans i18nKey="settings"></Trans>
            </span>
            <span className="env-dropdown-label">
              <i className="icon"></i>
              <Trans i18nKey="settings"></Trans>...
            </span>
          </button>
          <ul className="env-dropdown-menu">
            <li style={{ pointerEvents: "none", outline: "none", cursor: "default", userSelect: "none" }}>
              <b>
                <Trans i18nKey="fontSize"></Trans>
              </b>
            </li>
            {Object.keys(fontDefinition).map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => setFontSize(item)}
                  className="env-dropdown-menu-item"
                >
                  {fontDefinition[item].label}
                  {item === fontSize && (
                    <img
                      alt=""
                      className="env-icon1"
                      src="/assets/img/check1.svg"
                      style={{ marginRight: "5%" }}
                    />
                  )}
                </li>
              );
            })}
            <ContrastItems />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dropdowns;

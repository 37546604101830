import React from "react";
import { Trans } from 'react-i18next';
import NotificationBanner from "../../../common/notification";
import { t } from "i18next";
const StepFour = ({ onClick, regKey }) => {
  return (
    <div className="registration-email-confirmed-container">
      <div className="register-info-right-header">
        <Trans i18nKey="registrationConfirm"></Trans>
      </div>

      <div className="register-info-right-rules-confirm">
        <img alt="alt" src="/assets/img/check.svg" />
        <Trans i18nKey="registrationWeWillSendLInk"></Trans>
      </div>

      {
        regKey && (
          <>
              <NotificationBanner
                text={t("emailConfirmed")}
                backgroundColor="#56595a"
                canClose={true}
              />
              <button
                onClick={onClick}
                className="register-info-right-next-profile-button"
                style={{marginTop: "20px", height: 58 }}
                id="progress-next1-fourth"
              >
                <img alt="alt" src="/assets/img/next.svg" />
                <Trans i18nKey="registrationContinue"></Trans>
              </button>
            </>
            )
      }

          </div>
      );
};
      export default StepFour;

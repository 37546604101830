import moment from "moment";
import MetadataRows from "./MetadataRows";
import { useTranslation } from 'react-i18next';
import { useContext } from "react";
import { StateContext } from "../../../context";

const PublicInfo = ({ data }) => {
  const { t } = useTranslation();
  const { language } = useContext(StateContext);
  if (!Object.keys(data).length) return null;
  const renderedData = [
    {
      title: t('datasetDataGiver'),
      fields: [data.field_publisher_name ?? ""],
      extra: null,
    },
    {
      title: t('datasetDataGiverEmail'),
      fields: [data.field_publisher_email ?? ""],
      extra: null
    },
    {
      title: t('datasetNid'),
      fields: [data.nid ?? ""],
      extra: null
    },
    {
      title: t("datasetAddCategory"),
      fields: [data.parent_target_id ?? ""],
      extra: "category",
    },
    {
      title: data.name.includes(",") ? t("datasetAddSubCategories") : t("datasetAddSubCategory"),
      fields: data.name ? data.name.split(",") : [],
      extra: "category",
    },
    {
      title: t("datasetLegalFramework"),
      fields: [data.field_legal_framework ?? ""],
      extra: null,
    },
    {
      title: t("dataSetUpdated") + ":",
      fields: [data.field_changed_date ? moment(data.field_changed_date).format("DD.MM.YYYY HH:mm") : ""],
      extra: null,
    },
    {
      title: t("datasetAddUpdateFreq"),
      fields: [data.field_update_frequency ?? ""],
      extra: null,
    },
    {
      title: t("datasetAddDataFormat"),
      fields: [data.field_field_data_format_syntax ?? "", data.field_data_format_model ?? ""],
      extra: null,
    },
    {
      title: t("datasetScore"),
      fields: [!data?.field_score || data.field_score === "0" ? t("datasetNotRated") : data.field_score],
      extra: null,
    },
    // TODO: add file data fields (if not here then in the MetadataRows component)
    // file_title, filename file_upload_url
  ];

  // LVC-2524 7 punkts
  const tableStyle = {
    paddingTop: 39,
    paddingBottom: 29,
    width: "100%",
    tableLayout: "fixed",
  };

  return (
    <table
      className="card-content-table"
      style={tableStyle}
    >
      <tbody>
        <MetadataRows data={renderedData} />
      </tbody>
    </table>
  );
};

export default PublicInfo;

import { Banner } from "../common";
import StepFive from "./register/components/stepfive";
import { useParams } from "react-router-dom";
import { BACKEND_URL, post } from "../classes/api";
import { useQuery } from "@tanstack/react-query";
import Loader from "../common/loader";
import { useState } from "react";
import { useTranslation } from 'react-i18next';


const PasswordNew = () => {
  const { t } = useTranslation();
  const bannerData = {
    title: t("registrationPassworCreation"),
    path1Url: "/",
    path1:  t("workspaceStart"),
    path2: t("registrationPassworCreation"),
  };
  const bannerDataInvalid = {
    title: "Saite nav derīga",
  };
  const { id } = useParams();
  const [displayError, setDisplayError] = useState({ ok: false, msg: "" });

  const { data, isLoading, isError, error } = useQuery(
    ["resetPassword", id],
    async () => {
      return await post(`${BACKEND_URL}/api/checkPasswordKey`, JSON.stringify({ key: id }));
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 0, // disable cache
      cacheTime: 0,
    }
  );

  if (isLoading) return <Loader />;
  if (isError) {
    console.error(error);
    return null;
  }
  if (!data.data.ok) return <Banner bannerData={bannerDataInvalid} />;

  const resetPassword = async (data, id) => {
    const { password } = data;
    const resp = await post(`${BACKEND_URL}/api/resetPassword`, JSON.stringify({ key: id, password }));
    if (resp.ok) {  
      setDisplayError({ ok: true, msg: "Parole atjaunota!" });
      if (typeof window !== 'undefined') window?.scrollTo?.(0, 0);
    } else {
      setDisplayError({ ok: false, msg: resp?.data?.error ?? "Neizdevās atjaunot paroli!" });
    }
  };

  return (
    <>
      <Banner bannerData={bannerData} />
      {displayError.msg && (
        <div
          className="authorize-main-left-alert"
          style={{
            marginBottom: 10,
            marginTop: 10,
            height: 30,
            backgroundColor: displayError.ok ? "#4caf50" : "",
          }}
        >
          <div className="authorize-main-left-alert-text">
            {displayError.msg ?? "Neizdevās atjaunot paroli!"}
          </div>
          <img
            onClick={() => setDisplayError({ ok: false, msg: "" })}
            alt="alt"
            className="authorize-main-left-alert-icon"
            src="/assets/img/cancel3.svg"
          />
        </div>
      )}
      {displayError?.ok !=true && <StepFive
        onClick={(data) => {
          resetPassword(data, id);
        }}
      />}
    </>
  );
};

export default PasswordNew;

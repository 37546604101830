import { useState } from "react";
import { Banner } from "../common";
import { Input } from "./manaskopas/components";
import { useForm } from "react-hook-form";
import { BACKEND_URL, post } from "../classes/api";
import { t } from "i18next";
import { Trans } from "react-i18next";

const PasswordReset = () => {
  const emailRules = {
    required: { value: true, message: "fieldIsRequired" },
    pattern: {
      value:
        /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,}/,
      message: "invalidEmail",
    },
  };
  const bannerData = {
    title: t("generalRenewPassword"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("generalRenewPassword"),
  };
  const {
    register,
    getValues,
    getFieldState,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const [displayError, setDisplayError] = useState({ ok: false, msg: "" });

  const onSubmit = async () => {
    const resp = await post(
      `${BACKEND_URL}/api/sendPasswordEmail`,
      JSON.stringify({ email: getValues("email") })
    );
    if (resp.ok) {
      setDisplayError({ ok: true, msg: "E-pasts nosūtīts!", key: "emailSent" });
    } else {
      let errorKey = "";
      if (resp?.data?.error === "User not found!") {
        errorKey = "userNotFound";
      }
      setDisplayError({ ok: false, msg: resp?.data?.error ?? "Neizdevās nosūtīt epastu!", key: errorKey });
    }
  };

  return (
    <>
      <Banner bannerData={bannerData} />
      <div className="register-info-right">
        <div className="register-info-right-header">
          <span>{t("enterEmail")}</span>
        </div>

        <div className="register-info-right-description">
          <span>{t("enterEmailToSendNewPassword")}</span>
        </div>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`register-info-right-profile-info-input`}>
            <Input
              isMail
              name="email"
              placeholder={t("email")}
              register={register}
              errors={errors}
              rules={emailRules}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            {displayError.msg && (
              <div
                className="authorize-main-left-alert"
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                  height: 30,
                  backgroundColor: displayError.ok ? "#4caf50" : "",
                }}
              >
                <div className="authorize-main-left-alert-text">
                  <Trans i18nKey={displayError.key} />
                </div>
                <img
                  onClick={() =>
                    setDisplayError((prevDisplayError) => ({ ok: prevDisplayError.ok, msg: "", key: "" }))
                  }
                  alt="alt"
                  className="authorize-main-left-alert-icon"
                  src="/assets/img/cancel3.svg"
                />
              </div>
            )}
          </div>
          <br />
          {!displayError.ok && <button className="register-info-right-last-button">{t("sendLink")}</button>}
        </form>
      </div>
    </>
  );
};

export default PasswordReset;

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReportModal from "../../../modals/reportmodal";
import RateModal from "../../../modals/ratemodal";
import BasicInfoTable from "./basicinfotable";
import PublicInfo from "./publicinfo";
import { auth, fetch } from "../../../classes/auth";
import { StateContext } from "../../../context";
import { postCms } from "../../../classes/cmsApi";
import { Trans, useTranslation } from "react-i18next";
import { usr } from "../../../classes/user";
import ModalComponent from "../../../modals/modal";
import response_key_map from "../../../../responseKeyMap";
import { NotificationBanner } from "../../../common";
import { t } from "i18next";
import { formatLatvianDate } from "./dataholder";

import { DEFAULT_MAP_URL } from "../../../common/footer";

const DataDownload = ({
  files,
  publicFiles,
  nid,
  setErrorKey,
  dkName,
  lastChanged,
  sample = false,
  onClick = undefined,
  downloadable = true,
}) => {
  const { t } = useTranslation();
  const downloadBanner = {
    messageKey: "",
    ok: true,
    key: 1,
  };
  const { handleModalOpen, language } = useContext(StateContext);

  if (!files?.length) return null;

  const downloadFile = async (file_id, file_name, file_url = null) => {
    try {
      if (!downloadable) {
        return;
      }
      if (!!onClick) {
        onClick();
        return;
      }
      let response;
      if (file_url) {
        response = await fetch(file_url, {
          method: `GET`,
          headers: {
            crossDomain: true,
            Accept: `application/json`,
          },
        });
        if (!response.ok) {
          console.error(response);
          return;
        }
        response = await response.blob();
      } else {
        response = await postCms("api/v1/get/file/download-file", { file_id, nid }, null, {}, true);
        if (response.error) {
          console.error(response.error);
          return;
        }
      }
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file_name}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setErrorKey(err?.error?.response_key);
      handleModalOpen("error_modal");
    }
  };

  const formatFileDate = (date, file_id) => {
    if (file_id === 0) {
      return formatLatvianDate(lastChanged);
    }
    if (file_id === 1) {
      return formatLatvianDate(lastChanged);
    }
    return formatLatvianDate(date);
  };

  const formatFileName = (name, file_format, file_id, from_sipr) => {
    // if sample
    if (file_id === 0 || sample) {
      return `NPP_DK_${t("dataSample")}_${nid}.${file_format}`;
    }

    if (from_sipr) {
      return `NPP_DK_${nid}.${file_format}`;
    }
    return name;
  };

  const formatFileDescription = (description, file_id, file_format, from_sipr) => {
    // if sample
    if (file_id === 0 || sample) {
      return t("dataSampleFull");
    }
    if (from_sipr) {
      if (file_format.toLowerCase().includes("xml")) {
        return t("dataSetXML");
      }
      if (file_format.toLowerCase().includes("geojson")) {
        return t("dataSetGeoJSON");
      }
      return t("dataSet");
    }
    return description;
  };

  return (
    <>
      {downloadBanner.messageKey && (
        <NotificationBanner
          text={t(downloadBanner.messageKey)}
          backgroundColor={downloadBanner.ok ? "#33D751" : "#9e313a"}
          canClose={true}
          key={downloadBanner.key}
        />
      )}
      <div className="card-content-detail-tab-tabpane-content-download">
        {files?.map((file) => {
          const { file_id, from_sipr, file_title, file_title_en, file_name, file_url, file_datetime } = file;

          const url = file_name;
          const file_format = url.substring(url.lastIndexOf('.') + 1);
          const translatedTitle = language === "lv" ? file_title : file_title_en;

          const display_name = formatFileName(file_name, file_format, file_id, from_sipr);
          const display_description = formatFileDescription(translatedTitle, file_id, file_format, from_sipr);
          const display_datetime = formatFileDate(file_datetime, file_id);

          // file name - name of uploaded file
          // file title - description the data provider has given to the file
          // file datetime - date and time of the file upload
          return (
            <div className="card-content-detail-tab-tabpane-content-download-item" key={file_id}>
              <img alt="alt" src="/assets/img/download.svg" className={`${downloadable ? "" : "gray-out"}`} />
              <span
                className={`card-content-detail-tab-tabpane-content-download-item-filename ${
                  downloadable ? "" : "gray-out"
                }`}
                onClick={async () => {
                  if (!auth.isAuthorized() && !publicFiles) return handleModalOpen("auth");
                  await downloadFile(file_id, display_name, file_url);
                }}
              >
                <u>{display_name}</u>
              </span>
              <span className="card-content-detail-tab-tabpane-content-download-item-filetitle">
                {display_description}
              </span>
              <span className="card-content-detail-tab-tabpane-content-download-item-date">
                {display_datetime}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

const decodeUrl = (url) => {
  let correctedUrl = url.replace(/&amp;/g, "&");
  const decodedUrl = decodeURIComponent(correctedUrl);
  return decodedUrl;
};

const InfoBox = ({ data, apiKey, isSubbed }) => {
  const [files, setFiles] = useState([]);
  const [openTab, setOpenTab] = useState(null);
  const [errorKey, setErrorKey] = useState("");
  const { language } = useContext(StateContext);
  const sampleData = useMemo(
    () =>
      data?.field_field_data_sample && [
        {
          file_id: 0,
          file_name: `SampleData.${data?.field_field_data_sample.split(".").pop()}`,
          file_title: `SampleData.${data?.field_field_data_sample.split(".").pop()}`,
          file_url: data?.field_field_data_sample,
        },
      ],
    [data?.field_field_data_sample]
  );

  const handleFiles = useCallback(() => {
    if (!data?.title) return;
    if (data?.field_sipr_dataset_uid === "1") {
      return setFiles([
        ...(data?.field_field_data_format_syntax.includes("XML")
          ? [
              {
                file_id: 1,
                file_name: "Dataset.xml",
                file_title: "Dataset.xml",
                from_sipr: true,
              },
            ]
          : []),
        ...(data?.field_field_data_format_syntax.includes("GeoJSON")
          ? [
              {
                file_id: 2,
                file_name: "Dataset.geojson",
                file_title: "Dataset.geojson",
                from_sipr: true,
                format: "geojson",
                contentType: "json",
              },
            ]
          : []),
      ]);
    }

    // parse drupal text field to json
    let parser = new DOMParser();
    let doc = parser.parseFromString(data.title, "text/html");

    let decodedString = doc.documentElement.textContent;
    let jsonData = JSON.parse(decodedString);
    setFiles(jsonData);
  }, [data]);

  useEffect(() => {
    handleFiles();
  }, [handleFiles]);

  if (!Object.keys(data).length) return null;

  return (
    <div className="card-content-detail">
      {
        <ModalComponent
          id="error_modal"
          trigger={<div style={{ marginTop: "60px" }}></div>}
          small
          title={response_key_map[errorKey]?.title || t("errorOccured")}
          className={"modal-stack rate-modal"}
        >
          {response_key_map[errorKey]?.body || t("errorOccuredMessage")}
        </ModalComponent>
      }
      <div className="card-content-detail-tab">
        <div className="card-content-detail-tab-tabbutton">
          <button className="card-content-detail-tab-tabbutton-tablink active" id="defaultOpen">
            <Trans i18nKey="datasetDescription"></Trans>
          </button>
          <button
            onClick={() => {
              if (language === "lv")
                window.open(
                  data.field_link_to_map ? decodeUrl(data.field_link_to_map) : DEFAULT_MAP_URL + `${language}`
                );
              else
                window.open(
                  data.field_link_to_map_en
                    ? decodeUrl(data.field_link_to_map_en)
                    : DEFAULT_MAP_URL + `${language}`
                );
            }}
            className="card-content-detail-tab-tabbutton-tablink"
          >
            <img alt="alt" src="/assets/img/tab-icon.png" />
            <Trans i18nKey="datasetViewMap"></Trans>
          </button>
        </div>
      </div>
      <div>
        <div className="card-content-detail-tab-tabpane-header">
          <p className="card-content-detail-tab-tabpane-header-title">
            <span className="card-content-detail-tab-tabpane-content-value">
              {language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
            </span>
          </p>
          <br />
          <p className="card-content-detail-tab-tabpane-header-description">
            {language === "lv" ? data.field_dataset_description_lv : data.field_dataset_description}
          </p>
        </div>
        <img
          alt="alt"
          src="/assets/img/npp-datukopa-standarta-reklamkarogs-3.png"
          style={{ width: "100%" }}
        />
        <div className="card-content-detail-tab-tabpane-content">
          <span>
            {data.field_data_format_model && (
              <>
                <Trans i18nKey="datasetModel" />
                <span className="card-content-detail-tab-tabpane-content-value">
                  {data.field_data_format_model}
                </span>
              </>
            )}
          </span>
          {data.field_language_content && data.field_language_content !== "null" && (
            <span>
              <Trans i18nKey="datasetLanguage" />
              <span className="card-content-detail-tab-tabpane-content-value">
                {data.field_language_content
                  .split(", ")
                  .map((lang) => t(`language${lang}`))
                  .join(", ")}
              </span>
            </span>
          )}
          <span>
            <Trans i18nKey="datasetLegalFramework" />
            <span className="card-content-detail-tab-tabpane-content-value">
              {data.field_legal_framework?.includes("SSTP")
                ? "SSTP"
                : data.field_legal_framework?.includes("SRTI")
                ? "SRTI"
                : data.field_legal_framework?.includes("RTTI")
                ? "RTTI"
                : ""}
            </span>
          </span>
          <br />
          <span>
            <Trans i18nKey="datasetDistibutionMode" />
            <span className="card-content-detail-tab-tabpane-content-value">
              {data?.field_distribution_mode ?? ""}
            </span>
          </span>
          <br />
          <div className="card-content-detail-tab-tabpane-content-format">
            <div className="card-content-detail-tab-tabpane-content-format-label">
              <Trans i18nKey="datasetAvailableFormats"></Trans>
            </div>
            {data?.field_field_data_format_syntax
              .split(",")
              .map((syntax) => syntax.trim())
              .map((syntax) => (
                <span className="card-content-detail-tab-tabpane-content-format-tag">{syntax}</span>
              ))}
          </div>
          <div className="card-content-detail-tab-tabpane-content-download-container">
            {sampleData && (
              <DataDownload
                dkName={language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
                files={sampleData}
                nid={data.nid}
                setErrorKey={setErrorKey}
                publicFiles
                lastChanged={data.field_data_sample_datetime}
                sample={true}
                downloadable={true}
              />
            )}
            <DataDownload
              dkName={language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
              files={files}
              nid={data.nid}
              setErrorKey={setErrorKey}
              lastChanged={data.field_changed_date}
              downloadable={usr?.can("API dokumentācija (datu devējam)") ? false : true}
            />
            <DataDownload
              dkName={language === "lv" ? data.field_dataset_name_lv : data.field_dataset_name}
              files={[
                {
                  file_id: "0",
                  file_name: `NPP_metadata_${data.nid}`,
                  file_title: `mobilityDCAT-AP metadati`,
                  file_datetime: data.created,
                },
              ]}
              publicFiles
              nid={data.nid}
              setErrorKey={setErrorKey}
              lastChanged={data.created}
              onClick={() => {
                window.open(
                  `${window.location.origin}/api/v1/subscriber/metadata_dcat/${data?.nid}`,
                  "_blank"
                );
              }}
              downloadable={true}
            />
          </div>
          <div className="card-content-detail-tab-tabpane-content-accordion">
            <div
              className="card-content-detail-tab-tabpane-content-accordion-title menu-item active"
              onClick={() => {
                if (openTab === 2) {
                  setOpenTab(null);
                } else setOpenTab(2);
              }}
            >
              <img
                alt="alt"
                className="accordion-open-icon-plus"
                src={`/assets/img/${openTab === 2 ? "minus" : "plus"}.svg`}
              />
              <span>
                <Trans i18nKey="basicInfo" />
              </span>
            </div>
            <div
              className={`card-content-detail-tab-tabpane-content${
                openTab !== 2 && "-accordion-description"
              }`}
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PublicInfo data={data} />
            </div>
            <div
              className="card-content-detail-tab-tabpane-content-accordion-title menu-item active"
              id="defaultOpen"
              onClick={() => {
                if (openTab === 1) {
                  setOpenTab(null);
                } else setOpenTab(1);
              }}
            >
              <img
                alt="alt"
                className="accordion-open-icon-plus"
                src={`/assets/img/${openTab === 1 ? "minus" : "plus"}.svg`}
              />
              <span>
                <Trans i18nKey="additionalInfo" />
              </span>
            </div>

            <div
              className={`card-content-detail-tab-tabpane-content${
                openTab !== 1 && "-accordion-description"
              }`}
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BasicInfoTable data={{ ...data, datasetAddGeographicalCoverageCountry: "latvia" }} />
            </div>
          </div>
          {auth.isAuthorized() && usr.can("API dokumentācija (datu ņēmējam)") && isSubbed && (
            <>
              <ReportModal
                trigger={
                  <div className="card-content-detail-tab-tabpane-content-more" style={{ marginTop: "60px" }}>
                    <span className="card-content-detail-tab-tabpane-content-more-text">
                      <Trans i18nKey="datasetReportProblem"></Trans>
                    </span>
                    <div className="test"></div>
                  </div>
                }
                nid={data?.nid}
              />
              <RateModal
                trigger={
                  <div className="card-content-detail-tab-tabpane-content-more" style={{ marginTop: "60px" }}>
                    <span className="card-content-detail-tab-tabpane-content-more-text">
                      <Trans i18nKey="popupMessageRate"></Trans>
                    </span>
                    <div className="test"></div>
                  </div>
                }
                nid={data?.nid}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoBox;

const SmoothScrollLink = ({ href, children, style }) => {
  const handleClick = (event) => {
    event.preventDefault();
    document.querySelector(href).scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <a
      href={href}
      onClick={handleClick}
      style={{
        ...style,
      }}
    >
      {children}
    </a>
  );
};

export default SmoothScrollLink;
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../context";
import { useTranslation } from "react-i18next";
import { formatLatvianDate } from "../../card/components/dataholder";
import { highlightKeyword } from "../../search/search";
import { sanitize } from "dompurify";

const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const highlightTitle = (title, keyword, foregroundColor, backgroundColor) => {
  if (!keyword) return "<span>" + escapeHtml(title) + "</span>";
  // returns html with highlighted keyword
  const words = escapeHtml(title).split(" ");
  const highlighted = highlightKeyword(words, escapeHtml(keyword), foregroundColor, backgroundColor);
  return highlighted;
}

const CatalogList = ({ data, searchKeyword }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language, contrast } = useContext(StateContext);

  const [foregroundColor, setForegroundColor] = useState("yellow");
  const [backgroundColor, setBackgroundColor] = useState("black");

  useEffect(() => {
    if (contrast !== "contrast_blue") {
      if (contrast === "contrast_yellow") {
        setForegroundColor("yellow");
        setBackgroundColor("black");
      }
      if (contrast === "contrast_blackwhite") {
        setForegroundColor("white");
        setBackgroundColor("black");
      }
      if (contrast === "contrast_blackyellow") {
        setForegroundColor("black");
        setBackgroundColor("yellow");
      }
    } else {
      setForegroundColor("yellow");
      setBackgroundColor("black");
    }
  }, [contrast]);

  const renameDistributionMode = (mode) => {
    switch (mode) {
      case "api":
        return t("datasetCardValueapi");
      case "file":
        return t("datasetCardValuefile");
      default:
        return mode;
    }
  };
  return data.map((item) => (
    <div
      className="catalog-main-menu-form"
      key={item.nid}
      onClick={() => navigate(`/card/${item.uuid}`)}
      style={{ cursor: "pointer" }}
    >
      <div
        className="catalog-main-menu-form-title"
        dangerouslySetInnerHTML={{
          __html:
            sanitize(
              highlightTitle(
                language === "lv" ? item.field_dataset_name_lv : item.field_dataset_name,
                searchKeyword,
                foregroundColor,
                backgroundColor
              ))
        }}
      />
      <div className="catalog-main-menu-form-group">
        <div className="catalog-main-menu-form-text">{item.field_content_category ?? ""}</div>
      </div>
      <div className="catalog-main-menu-form-group">
        <div className="catalog-main-menu-form-text1">
          {renameDistributionMode(item.field_distribution_mode ?? "")}
        </div>
        <div className="pulse-animation">
          <img alt="alt" className="catalog-main-menu-form-statusicon" src="/assets/img/state.png" />
        </div>
      </div>
      {item.field_dzesanas_datums ? (
        <div className="catalog-main-menu-form-group">
          <div className="catalog-main-menu-form-text1">
            {t("datasetDeletableShort")}
            {formatLatvianDate(item.field_dzesanas_datums)}
          </div>
        </div>
      ) : (
        item.field_changed_date && (
          <div className="catalog-main-menu-form-group">
            <div className="catalog-main-menu-form-text1">{`${t("dataSetUpdated")}: ${formatLatvianDate(item.field_changed_date)}`}</div>
          </div>
        )
      )}
    </div>
  ));
};

export default CatalogList;

import { useNavigate } from "react-router-dom";

const SubNavbarData = ({ children, setNavBarOpened }) => {
  const navigate = useNavigate();
  if (!children) return null;

  return (
    <>
      {children.map((item, key) => {
        return (
          <button
            className={item.field_menu_icon ? "navbar-menus-tab-imgs-item" : item.field_menu_icon}
            key={key}
            onClick={() => {
              // contact's should open in new tab ?
              if (item.link__title.startsWith("http://") || item.link__title.startsWith("https://")) //  || item.link__title === "/lv/kontakti" 
                window.open(item.link__title, "_blank");
              else navigate(item.link__title);
              setNavBarOpened(false);
            }}
            style={{ textDecoration: "none" }}
          >
            {item.field_menu_icon && (
              <img alt="" className={item.field_menu_icon_class} src={item.field_menu_icon} />
            )}
            {item.title}
          </button>
        );
      })}
    </>
  );
};

export default SubNavbarData;

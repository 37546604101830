import { Trans } from "react-i18next";
import { usr as user } from "../classes/user";
import { useContext } from "react";
import { StateContext } from "../context";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const translateRole = (_role) => {
  const role = Array.isArray(_role) ? _role[0] : _role;
  if (role === "Datu ņēmējs") {
    return t("registrationDataRecipient");
  } else if (role === "Datu devējs") {
    return t("registrationDataProvider");
  } else return role;
};

const ProfileType = () => {
  return (
    <span className="dashboard-topic-right-top-text" style={{display: "block"}}> 
      <Trans i18nKey="userType"></Trans>
      <b>{user.roles.length ? translateRole(user.roles[0]) : `-`}</b>
    </span>
  );
};

const AuthBox = () => {
  const navigate = useNavigate();

  const renderHelp = ["/abonetaskopas"].includes(window.location.pathname);

  const { hasOpenedAuthBox, setHasOpenedAuthBox } = useContext(StateContext);


  return (
    <div className="dashboard-topic-right" 
      style={{
        paddingBottom: "1em",
      }}
    >
      <div className="dashboard-topic-right-top">
        <span className="dashboard-topic-right-top-text">
          <Trans i18nKey="youAreAuthorizedAs"></Trans>
        </span>
        <img alt="" src="/assets/img/info_icon.svg" style={{ opacity: 0.2 }} />
      </div>
      <span
        className={`dashboard-topic-right-top-text dashboard-topic-right-top-text-email authbox-arrow-${
          hasOpenedAuthBox ? "open" : "closed"
        } `}
        onClick={() => setHasOpenedAuthBox((prev) => !prev)}
      >
        <b>{user.email}</b>
      </span>
      {hasOpenedAuthBox && <ProfileType />}
      {renderHelp && (
        <div
          style={{ marginTop: "1em" }}
          className="catalog-header-right-text1"
          onClick={() =>
            navigate(
              `/palidziba?currentPage=${user?.can("API dokumentācija (datu devējam)") ? "helpDD" : "helpDN"}`
            )
          }
        >
          <img className="catalog-header-right-icon" alt="alt" src="/assets/img/book.svg" />
          <Trans i18nKey="pathingHelp"></Trans>
        </div>
      )}
    </div>
  );
};

export default AuthBox;

import { useState, useEffect, useCallback } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { set, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { auth } from "../../../classes/auth";
import { t } from "i18next";
import { Input } from "../../manaskopas/components";

const StepFive = ({ onClick }) => {
  const [ctx, setCtx] = useState(0);
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [verificationPercentage, setVerificationPercentage] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getFieldState,
    getValues,
    trigger,
  } = useForm({ mode: `all` });

  const calculateVerificationPercentage = useCallback((pw) => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const minLength = 9;

    let errorMessages = [];
    let percentage = 100;

    if (pw.length > minLength) {
      if (!uppercaseRegex.test(pw)) {
        percentage -= 25;
        errorMessages.push(t("atleastOneCapitalLetter"));
      }
      if (!lowercaseRegex.test(pw)) {
        percentage -= 25;
        errorMessages.push(t("atleastOneLowercaseLetter"));
      }
      if (!specialCharRegex.test(pw)) {
        percentage -= 25;
        errorMessages.push(t("atleastOneSpecialCharacter"));
      }
      if (!numberRegex.test(pw)) {
        percentage -= 25;
        errorMessages.push(t("atleastOneNumber"));
      }
      return { errorMessages, percentage };
    } else {
      errorMessages.push(`${t("atleast")} ${minLength} ${t("symbols")}`);
      return { errorMessages, percentage: 0 };
    }
  }, []);

  useEffect(() => {
    const subscription = watch(() => setCtx(ctx + 1));
    return () => subscription.unsubscribe();
  }, [watch, ctx]);

  return (
    <div className="register-info-right">
      <div className="register-info-right-header">
        <Trans i18nKey="registrationPassworCreationSecure"></Trans>
      </div>

      <div className="register-info-right-description">
        <Trans i18nKey="registrationPleasePassword"></Trans>
      </div>
      <br />
      <form onSubmit={handleSubmit((data) => onClick(data))}>
        {auth.isAuthorized() && (
          <div className={`register-info-right-profile-info-input`}>
            <input type="password" placeholder={t("oldPassword")} {...register(`oldPassword`)} />
          </div>
        )}
        <Input
          isPassword
          name={"password"}
          register={register}
          errors={errors}
          placeholder={auth.isAuthorized() ? t("newPassword") : t("password")}
          onChange={(e) => setPw1(e.target.value)}
          rules={{
            required: { message: "fieldIsRequired", value: true },
            validate: (val) => {
              const { percentage, errorMessages } = calculateVerificationPercentage(val);
              setVerificationPercentage(percentage);
              trigger("password2");
              if (percentage === 100) {
                return true;
              } else {
                return t("passwordMustConsistOf") + " " + errorMessages.join(", ");
              }
            },
          }}
          getFieldState={getFieldState}
          getValues={getValues}
        />
        <Input
          isPassword
          style={{ marginTop: "10px" }}
          name="password2"
          placeholder={auth.isAuthorized() ? t("newPasswordAgain") : t("passwordAgain")}
          register={register}
          errors={errors}
          getValues={getValues}
          getFieldState={getFieldState}
          rules={{
            onChange: (e) => setPw2(e.target.value),
            required: { message: "fieldIsRequired", value: true },
            validate: (val) => {
              return getValues()["password"] === val ? true : t("passwordsDoNotMatch");
            },
          }}
        />
        <div className="register-info-right-password-progressbar">
          <div className="register-info-right-password-progressbar-lines">
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                className={`register-info-right-password-progressbar-lines-item ${
                  index < verificationPercentage / 20
                    ? verificationPercentage > 99
                      ? "completed-good"
                      : "completed-mid"
                    : "completed-bad"
                }`}
              ></div>
            ))}
          </div>
          {verificationPercentage > 99 ? (
            <Trans i18nKey="registrationSafe"></Trans>
          ) : (
            <Trans i18nKey="registrationAlmostSafe"></Trans>
          )}
        </div>
        <button
          // onClick={onClick}
          className={`register-info-right-next-profile-button${
            Object.keys(errors).length === 0 && verificationPercentage > 0 ? `` : `-disabled`
          }`}
          id="progress-next2-third"
          disabled={Object.keys(errors).length || verificationPercentage === 0}
          style={{
            height: 58,
            ...(Object.keys(errors).length === 0 && verificationPercentage > 0
              ? {}
              : { filter: `grayscale(1)`, cursor: `not-allowed` }),
          }}
        >
          <img alt="altimage" src="/assets/img/next.svg" />
          <Trans i18nKey="datasetAddSave"></Trans>
        </button>
      </form>
      <GoogleReCaptcha
        onVerify={(token) => {
          window.reCaptchaToken = token;
        }}
        refreshReCaptcha={refreshReCaptcha}
      />
    </div>
  );
};

export default StepFive;

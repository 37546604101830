import { useEffect } from "react";

const Paginate = ({ setPage, page, maxPages }) => {
  useEffect(() => {
    if (page > maxPages && maxPages) {
      setPage(maxPages);
    } else if (page < 1) {
      setPage(1);
    }
  }, [page, maxPages, setPage]);

  const getPageList = () => {
    let pages = [];
    let startPage, endPage;

    if (maxPages <= 5) {
      startPage = 1;
      endPage = maxPages;
    } else {
      if (page <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (page + 2 >= maxPages) {
        startPage = maxPages - 4;
        endPage = maxPages;
      } else {
        startPage = page - 2;
        endPage = page + 2;
      }
    }

    if (startPage > 1) {
      pages.push(1);
    }
    if (startPage > 2) {
      pages.push("...");
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    if (endPage < maxPages - 1) {
      pages.push("...");
    }
    if (endPage < maxPages) {
      pages.push(maxPages);
    }

    return pages;
  };

  const pageList = getPageList();

  return (
    <>
      <ul className="paginate">
        <li className="paginate-btn">
          <div
            className="slidebar-slideshow-prev"
            onClick={() => {
              if (page === 1) return;
              setPage(page - 1);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            &#10094;
          </div>
        </li>

        {pageList.map((num, index) => (
          <li
            key={index}
            className={`paginate-numbers ${num === page ? "active" : ""}`}
            onClick={() => {
              if (num === "...") return;
              setPage(num);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            {num}
          </li>
        ))}

        <li className="paginate-btn">
          <div
            className="slidebar-slideshow-prev"
            onClick={() => {
              if (page === maxPages) return;
              setPage(page + 1);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            &#10095;
          </div>
        </li>
      </ul>
    </>
  );
};
export default Paginate;

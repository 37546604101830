import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "../../../context";
import { Trans } from 'react-i18next';

const Initial = ({ clickConsumer, clickPublisher }) => {
  const { language} = useContext(StateContext);

  return (
    <div className="register-info-right-container">
      <div className="register-info-right-header">
      <Trans i18nKey="registrationNewUserCreation"></Trans>
      </div>
      <div className="register-info-right-cards">
        <div className="register-info-right-cards-item">
          <div className="register-info-right-cards-item-avatar">
            <img alt="alt" src="/assets/img/type1.jpg" />
            <br />
          </div>
          <span style={{paddingTop:"10px"}}>
            <b><Trans i18nKey="registrationDataRecipient"></Trans></b>
          </span>
          <br />
          <span className="register-info-right-cards-item-description">
          <Trans i18nKey="registrationAccessNPPData"></Trans>
          </span>
          <br />
          <button onClick={clickConsumer} id="progress-next1-first">
          <Trans i18nKey="registrationWishToSubscribe"></Trans>
          </button>
        </div>
        <div className="register-info-right-cards-item">
          <div className="register-info-right-cards-item-avatar">
            <img alt="alt" src="/assets/img/type2.jpg" />
            <br />
          </div>
          <span style={{paddingTop:"10px"}}>
            <b><Trans i18nKey="datasetDataGiverPlain"></Trans></b>
          </span>
          <br />
          <span className="register-info-right-cards-item-description">
          <Trans i18nKey="registrationGivesDataToNPP"></Trans>
          </span>
          <br />
          <button onClick={clickPublisher} id="progress-next2-first">
            <Trans i18nKey="registrationIWishToPublish"></Trans>
          </button>
        </div>
      </div>
      <div className="register-info-right-help"><Trans i18nKey="registrationWishToKnowMore"></Trans></div>
      <Link to={(language === "en" ? "/en" : "/lv") + "/klusti"} target={"_blank"} className="register-info-right-next">
        <img alt="alt" src="/assets/img/next.svg" />
        <u><Trans i18nKey="registrationDataReciverAndGiverAdvantages"></Trans></u>
      </Link>
    </div>
  );
};

export default Initial;

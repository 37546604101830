import { Trans } from "react-i18next";

const response_key_map = {
    "max_file_request_reached": {
        "title": <Trans i18nKey="universalPopupMaxFileRequestReachedTitle"></Trans>,
        "body": <Trans i18nKey="universalPopupMaxFileRequestReachedDescription"></Trans>
    },
    "no_files_found": {
        "title": <Trans i18nKey="emptyDataSet"></Trans>,
        "body": <Trans i18nKey="siprDkNotFound"></Trans>
    }
};

export default response_key_map;
import React, { useState } from "react";
import { DataBox, Dropdown, MultipleDropdown } from "..";
import { useTranslation } from "react-i18next";
import Field from "../../../../common/atoms/textfield";

export const xmlSyntax = { key: "xml", value: "XML" };
export const dataModelSyntaxOptions = {
  "598": [xmlSyntax], // datex_ii_v3
  "599": [xmlSyntax], // datex_ii_v2
  "601": [xmlSyntax], // date_ii_light
  "602": [xmlSyntax], // netex_cents_16614
  "603": [xmlSyntax], // siri_cents_15531
};

const DataFormatBox = ({
  defval,
  isNew,
  register,
  getValues = () => ({}),
  setFiles,
  getFieldState,
  schemas,
  errors,
  setValue,
  dataFormatModel,
  dataModelSyntax,
  selectedSyntax,
  setSelectedSyntax,
  resetSelectedSyntax,
  setResetSelectedSyntax,
  removeValidationErrors,
  publicationTypes,
  checkboxState,
}) => {
  const { t } = useTranslation();
  const [selectedModel, setSelectedModel] = useState(defval?.data_format_model ?? "");

  const children =
    defval.data_set_statuss === "publish"
      ? [
          <>
            <Field
              title={t("datasetAddDataModel")}
              text={dataFormatModel?.find((v) => v.key === defval.data_format_model)?.value ?? "-"}
            />
            <Field
              title={t("datasetAddDataModelSchema")}
              text={schemas?.find((v) => v.key === defval?.data_format_schema)?.value ?? "-"}
            />
            <Field
              title={t("datasetAddDataModelSyntax")}
              text={
                defval?.field_data_format_syntax
                  ? Array.isArray(defval?.field_data_format_syntax)
                    ? defval?.field_data_format_syntax
                        .map((v) => dataModelSyntax.find((syntax) => syntax.key === v)?.value)
                        .join(", ")
                    : dataModelSyntax.find((v) => v.key === defval?.field_data_format_syntax)?.value
                  : "-"
              }
            />
          </>,
        ]
      : [
          <div className="input-group">
            <Dropdown
              fieldName="data_format_model"
              placeholder={t("datasetAddDataModel")}
              defval={defval?.data_format_model}
              register={register}
              errors={errors}
              rules={{
                required: { message: "fieldIsRequired", value: true },
              }}
              onChange={(selectedValue) => {
                if (dataModelSyntaxOptions[selectedValue]) {
                  setSelectedSyntax([dataModelSyntaxOptions[selectedValue][0].key]);
                  setValue("field_data_format_syntax", dataModelSyntaxOptions[selectedValue][0].key);
                  setResetSelectedSyntax((current) => !current);
                  setFiles([]);
                }
                setSelectedModel(selectedValue);
                removeValidationErrors();
              }}
              onBeforeChange={
                isNew
                  ? undefined
                  : (value) => {
                      // confirm, that attached files should be removed, if syntax is changed
                      if (value in dataModelSyntaxOptions && selectedSyntax !== "xml")
                        return !window.confirm(t("popupMessageChangeFormat"));
                    }
              }
              options={dataFormatModel}
              setValue={setValue}
              getValues={getValues}
              getFieldState={getFieldState}
            />
            {(defval?.distribution_mode === "file" || checkboxState.check1) && (
              <MultipleDropdown
                fieldName="field_data_format_syntax"
                placeholder={t("datasetAddDataModelSyntax")}
                defval={
                  defval?.field_data_format_syntax
                    ? Array.isArray(defval?.field_data_format_syntax)
                      ? defval?.field_data_format_syntax
                      : [defval?.field_data_format_syntax]
                    : []
                }
                register={register}
                errors={errors}
                rules={{
                  required: { message: "fieldIsRequired", value: true },
                }}
                // onBeforeChange={
                //   isNew
                //     ? undefined
                //     : () => {
                //         return !window.confirm(t("popupMessageChangeSyntax"));
                //       }
                // }
                onChange={() => {
                  setSelectedSyntax(getValues("field_data_format_syntax"));
                }}
                options={dataModelSyntaxOptions[selectedModel] ?? dataModelSyntax}
                setValue={setValue}
                resetOptions={resetSelectedSyntax}
                getValues={getValues}
                getFieldState={getFieldState}
              />
            )}
            {(defval?.distribution_mode === "api" || checkboxState.check2) && (
              <MultipleDropdown
                fieldName="field_data_format_syntax"
                placeholder={t("datasetAddDataModelSyntax")}
                defval={
                  defval?.field_data_format_syntax
                    ? Array.isArray(defval?.field_data_format_syntax)
                      ? defval?.field_data_format_syntax
                      : [defval?.field_data_format_syntax]
                    : []
                }
                register={register}
                errors={errors}
                rules={{
                  required: { message: "fieldIsRequired", value: true },
                }}
                options={dataModelSyntaxOptions[selectedModel] ?? dataModelSyntax}
                setValue={setValue}
                resetOptions={resetSelectedSyntax}
                getValues={getValues}
                getFieldState={getFieldState}
              />
            )}
            {selectedSyntax?.includes("xml") && (
              <Dropdown
                fieldName="data_format_schema"
                placeholder={t("datasetAddDataModelSchema")}
                defval={defval?.data_format_schema}
                register={register}
                errors={errors}
                rules={{
                  required: selectedSyntax === "xml",
                }}
                onChange={() => removeValidationErrors()}
                options={schemas}
                setValue={setValue}
                getValues={getValues}
                getFieldState={getFieldState}
              />
            )}
            {getValues("data_format_model")?.includes("datex") && (
              <Dropdown
                fieldName="datex_publication_type"
                placeholder={t("datasetAddDatexPublicationClass")}
                defval={defval?.datex_publication_type}
                register={register}
                errors={errors}
                rules={{
                  required: { message: "fieldIsRequired", value: true },
                }}
                onBeforeChange={
                  isNew
                    ? undefined
                    : (value) => {
                        // confirm, that attached files should be removed, if syntax is changed
                        if (value === "situation")
                          return !window.confirm(t("popupMessageChangeDatexPublicationType"));
                      }
                }
                resetOptions={resetSelectedSyntax}
                options={publicationTypes}
                setValue={setValue}
                getValues={getValues}
                getFieldState={getFieldState}
              />
            )}
          </div>,
        ];

  return <DataBox label={t("datasetAddDataFormat")} children={children} />;
};

export default DataFormatBox;

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { StateContext } from "../../../context";
import CheckBox from "../../../common/checkbox";
import { getCms } from "../../../classes/cmsApi";
import { useQuery } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import "./index.css";
import { useSearchParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import { t } from "i18next";
import moment from "moment";
import { translateValues } from "../../manaskopas/components/overalldata";
import { dataModelSyntaxOptions, xmlSyntax } from "../../manaskopas/components/formitems/dataformat";

const emptyOptions = {
  metadataCategories: [],
  metadataSubCategories: [],
  dataFormats: [],
  dataModel: [],
  // dataTags: [],
  eventTypes: [],
  languages: [],
  esRegulas: [],
  users: [],
};

// const mapOptions = {
//   currentTrafficEvents: "3668,7299,14,847,17938,7301,17977,126,266",
//   drivingConditionsWarnings: "10,12,16,17,18,23,8111,7294,17990,212,22733,227,240,28623,28625",
//   plannedEvents: "7300,7301,17977",
//   camerasAndVehicles: "140,279,155,21490",
//   chargingAndFillingPoints: "65,76",
//   parkingLots: "6,8158",
//   weightAndDimensionsRestrictions: "44751",
//   roadNetworkData: "8160,28,35,292",
// };

const getDropdownValues = async (language) => {
  try {
    // nested data categories
    const respCategories = await getCms("public/data-categories", language);

    const removeDuplicates = (arr, key) => {
      const seen = new Set();
      return arr.filter((item) => {
        const value = item[key][0].value;
        if (seen.has(value)) {
          return false;
        } else {
          seen.add(value);
          return true;
        }
      });
    };

    const uniqueCategories = removeDuplicates(respCategories, "tid");

    const buildHierarchy = (terms) => {
      const hierarchy = [];

      terms.forEach((term) => {
        if (!term.parent[0].target_uuid) {
          const parentTerm = {
            key: term.tid[0].value,
            value: term.name[0].value,
            children: [],
          };

          terms.forEach((innerTerm) => {
            if (innerTerm.parent[0].target_id === parentTerm.key) {
              const childTerm = {
                key: innerTerm.tid[0].value,
                value: innerTerm.name[0].value,
              };
              parentTerm.children.push(childTerm);
            }
          });

          hierarchy.push(parentTerm);
        }
      });

      return hierarchy;
    };

    const nestedTaxonomy = buildHierarchy(uniqueCategories);

    // users
    // const respUsers = await getCms("public/user/list");
    // const users = respUsers.map((user) => ({ key: user.uid, value: user.name }));

    // taxonomies and Text(Lists)
    const resp = await getCms("public/metadata-dropdown/list", language);

    const data = {
      metadataCategories:
        nestedTaxonomy?.map((category) => ({
          key: category.key,
          value: category.value,
          children: category.children,
        })) ?? [],
      dataFormats: translateValues(resp.field_field_data_format_syntax ?? []),
      dataModel: translateValues(resp.term_data_format_model ?? []),
      eventTypes: [], // dont know what is this
      languages: translateValues(resp.field_language_content ?? []),
      esRegulas: translateValues(resp.regula ?? []),
    };
    return data;
  } catch (err) {
    console.error(err);
    return emptyOptions;
  }
};

const useSort = (setFilter) => {
  const { t } = useTranslation();
  const { language } = useContext(StateContext);
  const prevLanguage = useRef(language);

  const [params] = useSearchParams();
  const sort_by = useMemo(() => params.get("sort_by"), [params]);

  const [defaultKey, defaultDirection] = useMemo(() => {
    if (!sort_by) return ["field_changed_date_value", "desc"];
    const splitSort = sort_by.split("_");
    const direction = splitSort.pop();
    const key = splitSort.join("_");

    return [key, direction];
  }, [sort_by]);

  const [selected, setSelected] = useState(defaultKey);
  const [sort, setSort] = useState(defaultDirection);

  const options = useMemo(
    () => [
      {
        title: t("generalSortByTitle"),
        key: language === "lv" ? "field_dataset_name_lv_value" : "field_dataset_name_value",
      },
      {
        title: t("generalSortByDateCreated"),
        key: "created",
      },
      {
        title: t("generalSortByDateUpdated"),
        key: "field_changed_date_value",
      },
      {
        title: t("generalSortByUser"),
        key: "username",
      },
    ],
    [language, t]
  );

  const handleSort = useCallback(
    ({ key, direction }) => {
      setSort(direction);
      setSelected(key);
      setFilter({ sort_by: `${key}_${direction}` });
    },
    [setFilter]
  );

  useEffect(() => {
    if (prevLanguage.current === language) return;
    prevLanguage.current = language;

    if (selected.includes("field_dataset_name")) {
      const newKey = language === "lv" ? "field_dataset_name_lv_value" : "field_dataset_name_value";
      handleSort({ key: newKey, direction: sort });
    }
  }, [handleSort, language, selected, sort]);

  return [options, selected, sort, handleSort];
};

const useCheckboxes = () => {
  const { t } = useTranslation();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const checkboxes = useMemo(
    () => [
      {
        id: 1,
        title: t("generalOnlyApi"),
        key: "field_publishing_api_key_value",
        param: "0",
      },
      // {
      //   id: 2,
      //   title: t("generalOnlyMap"),
      //   key: "",
      //   param: {},
      // },
      {
        id: 3,
        title: t("generalRecents"),
        key: "field_changed_date_value",
        param: "-12 months",
      },
    ],
    [t]
  );

  return [checkboxes, selectedCheckboxes, setSelectedCheckboxes];
};

const useDropdowns = () => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useSearchParams();
  const { language } = useContext(StateContext);

  const {
    data: {
      metadataCategories,
      dataFormats,
      dataModel,
      // dataTags,
      // eventTypes,
      languages,
      esRegulas,
      // users,
    },
  } = useQuery({
    queryKey: ["dataset-filter-dropdownValues", language],
    queryFn: () => getDropdownValues(language),
    placeholderData: emptyOptions,
    refetchOnWindowFocus: false,
  });

  const metadataSubCategories = useMemo(() => {
    if (!selectedOptions.get("field_content_category_parent_target_id")) return [];
    return (
      metadataCategories.find(
        (category) => `${category.key}` === selectedOptions.get("field_content_category_parent_target_id")
      )?.children ?? []
    );
  }, [metadataCategories, selectedOptions]);

  const filteredDataFormats = useMemo(() => {
    const selectedModel = selectedOptions.get("field_data_format_model_value");

    // filter out syntax options selected model doesnt support
    if (selectedModel && dataModelSyntaxOptions[selectedModel]) {
      return dataModelSyntaxOptions[selectedModel];
    }
    return dataFormats;
  }, [dataFormats, selectedOptions]);

  useEffect(() => {
    const selectedModel = selectedOptions.get("field_data_format_model_value");
    const selectedSyntax = selectedOptions.get("field_field_data_format_syntax_value");

    if (!selectedSyntax || !selectedModel) return;
    // Reset the selected syntax if model doesnt support it
    if (selectedModel in dataModelSyntaxOptions && selectedSyntax !== xmlSyntax.key) {
      const updatedOptions = new URLSearchParams(selectedOptions);
      updatedOptions.set("field_field_data_format_syntax_value", undefined);
      setSelectedOptions(updatedOptions);
    }
  }, [selectedOptions, setSelectedOptions]);

  const dropdowns = useMemo(
    () => [
      {
        title: t("generalAllCategories"),
        image: "/assets/img/visas.svg",
        key: "field_content_category_parent_target_id",
        items: [{ key: undefined, value: t("generalAllCategories") }, ...metadataCategories],
      },
      ...(metadataSubCategories.length > 0
        ? [
            {
              title: t("generalAllSubCategories"),
              image: "/assets/img/visas.svg",
              key: "field_content_category_target_id",
              items: [{ key: undefined, value: t("generalAllSubCategories") }, ...metadataSubCategories],
            },
          ]
        : []),
      {
        title: t("generalAllDataFormats"),
        image: "/assets/img/note.svg",
        key: "field_field_data_format_syntax_value",
        items: [{ key: undefined, value: t("generalAllDataFormats") }, ...filteredDataFormats],
      },
      {
        title: t("generalAllDataModels"),
        image: null,
        key: "field_data_format_model_value",
        items: [{ key: undefined, value: t("generalAllDataModels") }, ...dataModel],
      },
      {
        title: t("allEURegulas"),
        key: "field_legal_framework_value",
        items: [{ key: undefined, value: t("allEURegulas") }, ...esRegulas],
      },
    ],
    [t, metadataCategories, metadataSubCategories, filteredDataFormats, dataModel, esRegulas]
  );

  return dropdowns;
};

const MapToggle = ({ setFilter }) => {
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setFilter({ field_link_to_map_uri: checked ? undefined : "0" });
    setChecked((current) => !current);
  };

  return (
    <div className="catalog-main-filter-option">
      <div className="catalog-main-filter-option-text">
        <Trans i18nKey="generalFiltersAll"></Trans>
      </div>
      <div className="catalog-main-filter-option-checkbox" onClick={handleClick}>
        <div
          className={`catalog-main-filter-option-checkbox-switcher catalog-main-filter-option-checkbox-check${
            checked ? "T" : "F"
          }`}
        >
          <div className="catalog-main-filter-option-checkbox-btn"></div>
        </div>
      </div>

      <div className="catalog-main-filter-option-text">
        <Trans i18nKey="generalFiltersWithMap"></Trans>
      </div>
    </div>
  );
};

const FilterDropdown = ({ item, setFilter, openKey, setOpenKey }) => {
  const [selectedOptions] = useSearchParams();

  const selected = useMemo(
    () => item?.items.find((i) => `${i.key}` === selectedOptions.get(item.key))?.value ?? item.title,
    [item?.items, item.key, item.title, selectedOptions]
  );

  return (
    <>
      <div onClick={() => setOpenKey((current) => (current !== item.key ? item.key : ""))}>
        <div className="filter-dropdown">
          <button className="filter-dropdown-btn">
            <div>
              {/* {item?.image && (
                <img className="catalog-main-filter-dropdown-icon" alt="alt" src={item.image} />
              )} */}
              <span className="filter-dropdown-value">{selected}</span>
            </div>
          </button>
          <ul className="filter-dropdown-menu" style={{ display: openKey === item.key ? "block" : "none" }}>
            {item?.items.map((i) => (
              <li
                key={i.key}
                style={{ paddingLeft: item?.image ? 36 : 15 }}
                onClick={() => setFilter({ [item?.key]: i?.key })}
              >
                {t(i?.value)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

const SortOrder = ({ onClick, direction }) => {
  const rotationStyle = useMemo(() => {
    return direction === "asc" ? { transform: "rotate(-90deg)" } : { transform: "rotate(90deg)" };
  }, [direction]);

  return (
    <div className="sort-order-btn" onClick={onClick}>
      <img
        alt="alt"
        className="profile-main-left-linkform-link-icon-blue"
        src="/assets/img/next.svg"
        style={{
          ...rotationStyle,
          margin: 0,
          width: "1.5em",
          height: "1.5em",
          transition: "transform 0.2s ease-in-out",
        }}
      />
    </div>
  );
};

const Sort = ({ setFilter, openKey, setOpenKey }) => {
  const [options, selected, sort, handleSort] = useSort(setFilter);

  return (
    <>
      <div className="catalog-main-filter-group">
        <div className="catalog-main-sort-group">
          <div
            className="filter-dropdown"
            onClick={() => setOpenKey(openKey === "sort_order" ? "" : "sort_order")}
          >
            <button className="filter-dropdown-btn">
              <div>
                <span className="filter-dropdown-value">
                  {options.find((o) => o.key === selected)?.title ?? "sort"}
                </span>
              </div>
            </button>
            <ul
              className="filter-dropdown-menu"
              style={{ display: openKey === "sort_order" ? "block" : "none" }}
            >
              {options.map((i) => (
                <li
                  key={i.key}
                  onClick={() => {
                    handleSort({ key: i?.key, direction: sort });
                  }}
                >
                  {i?.title}
                </li>
              ))}
            </ul>
          </div>

          <SortOrder
            onClick={() => handleSort({ key: selected, direction: sort === "asc" ? "desc" : "asc" })}
            direction={sort}
          />
        </div>
      </div>
    </>
  );
};

const Dropdowns = ({ setFilter, openKey, setOpenKey }) => {
  const dropdowns = useDropdowns();

  return (
    <div className="catalog-main-filter-group">
      {dropdowns.map((item) => (
        <FilterDropdown
          item={item}
          key={item.key}
          setFilter={setFilter}
          openKey={openKey}
          setOpenKey={setOpenKey}
        />
      ))}
    </div>
  );
};

const CheckBoxes = ({ setFilter }) => {
  const [checkboxes, selectedCheckboxes, setSelectedCheckboxes] = useCheckboxes();

  const handleCheckboxes = useCallback(
    (id) => {
      const selected = checkboxes.find((item) => item.id === id);
      if (!selected) return;
      setSelectedCheckboxes((prevSelectedCheckboxes) => {
        const index = prevSelectedCheckboxes.indexOf(id);
        if (index !== -1) {
          const updatedCheckboxes = [...prevSelectedCheckboxes];
          updatedCheckboxes.splice(index, 1);
          setFilter({ [selected.key]: undefined });
          return updatedCheckboxes;
        } else {
          setFilter({ [selected.key]: selected.param });
          return [...prevSelectedCheckboxes, id];
        }
      });
    },
    [checkboxes, setFilter, setSelectedCheckboxes]
  );

  return (
    <div className="catalog-main-filter-group">
      {checkboxes.map((item, index) => (
        <CheckBox
          key={index}
          text={item.title}
          checked={selectedCheckboxes.includes(item.id)}
          onClick={() => handleCheckboxes(item.id)}
        />
      ))}
    </div>
  );
};

export const DateTimePickerItem = ({ title, value, onChange, maxDate, minDate }) => {
  return (
    <div className="filter-calendar-container">
      <span className="date-filter-dropdown-label">{title}</span>
      <DateTimePicker
        className="filter-calendar"
        onChange={onChange}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
        locale="lv"
        disableClock
      />
    </div>
  );
};

const DateTime = ({ setFilter }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const maxDate = useMemo(() => moment().endOf("day").toDate(), []);

  return (
    <div className="catalog-main-filter-group">
      <div className="">{t("lastUpdated")}:</div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {/* Date from */}
        <DateTimePickerItem
          title={`${t("after")}: `}
          onChange={(val) => {
            setStartDate(val);
            setFilter({
              field_changed_date_value_from: val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : undefined,
            });
          }}
          value={startDate}
          maxDate={endDate ?? maxDate}
        />

        {/* Date to */}
        <DateTimePickerItem
          title={`${t("before")}: `}
          onChange={(val) => {
            setEndDate(val);
            setFilter({
              field_changed_date_value_to: val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : undefined,
            });
          }}
          value={endDate}
          minDate={startDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};

const AdvancedSearch = ({ setFilter }) => {
  const [open, setOpen] = useState(false);
  const rotationStyle = useMemo(() => {
    return open ? { transform: "rotate(180deg)" } : {};
  }, [open]);

  return (
    <div className="catalog-main-filter-group">
      <div className="catalog-main-filter-link link" onClick={() => setOpen((current) => !current)}>
        <span className="catalog-main-filter-link-text">
          <Trans i18nKey="generalAdvancedSearch"></Trans>
        </span>
        <div className="test" style={rotationStyle} />
      </div>
      <div
        style={{
          display: open ? "block" : "none",
          transition: "display 0.5s",
          padding: "1rem",
        }}
      >
        <DateTime setFilter={setFilter} />
      </div>
    </div>
  );
};

const TotalItems = ({ totalItems, filters, resetFilters }) => {
  const canReset = useMemo(() => {
    for (const [key] of Array.from(filters)) {
      if (key !== "page" && key !== "sort_by") return true;
    }
    return false;
  }, [filters]);

  return (
    <div className="catalog-main-filter-group">
      <div className="catalog-main-filter-result">
        <span className="catalog-main-filter-result-text">
          <Trans i18nKey="generalFilteredDS"></Trans>
        </span>
        &nbsp;
        <span className="catalog-main-filter-result-number">{totalItems ?? 0}</span>
        &nbsp;
        <span className="catalog-main-filter-result-text">
          <Trans i18nKey="titleDataSets"></Trans>
        </span>
        {canReset && (
          <>
            &nbsp;
            <div className="catalog-main-filter-link link" onClick={resetFilters}>
              <span className="catalog-main-filter-link-text">Rādīt visas</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const Filters = ({ filters, setFilter, resetFilters, totalItems }) => {
  const [openKey, setOpenKey] = useState("");

  return (
    <div className="catalog-main-filter">
      <div className="catalog-main-filter-title">
        <Trans i18nKey="generalFilters"></Trans>
      </div>

      <MapToggle setFilter={setFilter} />
      <Sort setFilter={setFilter} openKey={openKey} setOpenKey={setOpenKey} />
      <Dropdowns setFilter={setFilter} openKey={openKey} setOpenKey={setOpenKey} />
      <CheckBoxes setFilter={setFilter} />
      <DateTime setFilter={setFilter} />
      {/* <AdvancedSearch setFilter={setFilter} /> */}
      <TotalItems totalItems={totalItems} filters={filters} resetFilters={resetFilters} />
    </div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getCms } from "../../classes/cmsApi";
import { AuthBox, Banner } from "../../common";
import Loader from "../../common/loader";
import { extractDrupalData } from "../../helpers";
import { ErrorMessage, ReloadPageButton } from "./components/errormessage";
import Table from "./components/tables/Table";
import { useTranslation, Trans } from "react-i18next";
import { useEffect, useState } from "react";
import SmoothScrollLink from "../../components/SmoothScrollLink";

export const useDataSetsData = () => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    [`dataSets`],
    async () => {
      const res = await getCms("api/v1/get/own/metadata/list");
      return {
        active: extractDrupalData(res).filter((item) =>
          ["publish", "deletable"].includes(item.data_set_statuss)
        ),
        inactive: extractDrupalData(res).filter(
          (item) => !["publish", "deletable"].includes(item.data_set_statuss)
        ),
      };
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return { data, isLoading, isError, error, refetch };
};

const ErrorBanner = ({ textKey, color, setErrorKey }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className="authorize-main-left-alert"
      style={{
        marginBottom: 10,
        marginTop: 10,
        height: 30,
        backgroundColor: color,
      }}
    >
      <div className="authorize-main-left-alert-text">
        <Trans i18nKey={textKey} />
      </div>
      <img
        onClick={() => {
          navigate(location.pathname, { replace: true }); // clear state
          setErrorKey(false);
        }}
        alt="close-icon"
        className="authorize-main-left-alert-icon"
        src="/assets/img/cancel3.svg"
      />
    </div>
  );
};

const ButtonAndLinks = ({ publishedCount, inactiveCount, errorCount }) => {
  const navigate = useNavigate();

  return (
    <div className="manaskopas-main-sub5" style={{ display: "flex", justifyContent: "start" }}>
      <button className="manaskopas-main-sub5-btn5" type="button" onClick={() => navigate("/manaskopas/new")}>
        <img alt="alt" className="manaskopas-main-sub5-icon" src="/assets/img/add.svg" />
        <p className="manaskopas-main-sub5-font">
          <Trans i18nKey="addNewDS"></Trans>
        </p>
      </button>
      <div className="manaskopas-main-sub5-links">
        <SmoothScrollLink
          href="#published-table"
          style={{
            textDecoration: "underline",
            color: "#5b5b5b",
            textDecorationColor: "#969696",
            cursor: "pointer",
            display: "flex",
          }}
        >
          <img
            alt="arrow-down"
            className="change-black-img-to-gray"
            style={{ rotate: "90deg", width: "1em" }}
            src="/assets/img/next.svg"
          />
          <div style={{ paddingLeft: "0.5em" }}>
            <Trans i18nKey="published" /> ({publishedCount})
          </div>
        </SmoothScrollLink>
        <SmoothScrollLink
          style={{
            textDecoration: "underline",
            color: "#5b5b5b",
            textDecorationColor: "#969696",
            cursor: "pointer",
            display: "flex",
          }}
          href="#inactive-table"
        >
          <img
            alt="arrow-down"
            className="change-black-img-to-gray"
            style={{ rotate: "90deg", width: "1em" }}
            src="/assets/img/next.svg"
          />
          <div style={{ paddingLeft: "0.5em" }}>
            <Trans i18nKey="notPublished" /> ({inactiveCount})
          </div>
        </SmoothScrollLink>
        <div style={{ color: "#9f1b33", display: "flex", alignItems: "center" }}>
          <img
            alt="warning-icon"
            style={{ width: "22px", height: "19px" }}
            className="change-black-img-to-red "
            src="/assets/img/warn.svg"
          />
          <div style={{ paddingLeft: "0.5em" }}>
            <Trans i18nKey="withWarnings" />: {errorCount}
          </div>
        </div>
      </div>
    </div>
  );
};

const errorCount = (data) => {
  let errorCount = 0;
  if (data?.active) {
    data.active.forEach((item) => {
      if (item.error_description) {
        errorCount++;
      }
    });
  }
  if (data?.inactive) {
    data.inactive.forEach((item) => {
      if (item.error_description) {
        errorCount++;
      }
    });
  }
  return errorCount;
};

const ManasKopas = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const deletedDsKey = location.state?.deleted ? "datasetSubmittedForDeletion" : null;
  const [bannerError, setBannerError] = useState({
    key: deletedDsKey ?? null,
    color: "#4caf50",
  });
  const { data, isLoading, isError, refetch } = useDataSetsData();

  const bannerData = {
    title: t("workspacePublishedData"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
    path3: t("workspacePublishedData"),
    img: {
      href: "/assets/img/wi.svg",
      className: "manaskopas-title-left-stitle-icon",
    },
  };
  useEffect(() => {
    if (bannerError.key) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [bannerError.key]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Banner bannerData={bannerData}>
        <AuthBox />
      </Banner>
      {isError && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <ErrorMessage text={t("failedToLoadDS")} />
          <ReloadPageButton style={{ marginTop: 16 }} />
        </div>
      )}
      {bannerError.key && (
        <ErrorBanner setErrorKey={setBannerError} textKey={bannerError.key} color={bannerError.color} />
      )}

      <ButtonAndLinks
        publishedCount={data?.active?.length}
        inactiveCount={data?.inactive?.length}
        errorCount={errorCount(data)}
      />

      <div className="manaskopas-main" style={{ marginBottom: "5em" }}>
        <Table
          id="published-table"
          onSort={() => refetch()}
          data={{
            title: t("activeDataSets"),
            titleTooltipText: t("activeDataSetsTooltip"),
            data: data?.active,
          }}
          toggleSubmit={() => refetch()}
          errorBannerCallback={(key) => setBannerError({ key, color: "#9f1b33" })}
        />
        <Table
          id="inactive-table"
          onSort={() => refetch()}
          data={{
            title: t("inactiveDataSets"),
            titleTooltipText: t("inactiveDataSetsTooltip"),
            data: data?.inactive,
          }}
          toggleSubmit={() => refetch()}
          errorBannerCallback={(key) => setBannerError({ key, color: "#9f1b33" })}
        />
      </div>
    </>
  );
};

export default ManasKopas;

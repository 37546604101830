import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Loader from "../common/loader";
import { StateContext } from "../context";
import { ErrorMessage, ReloadPageButton } from "../pages/manaskopas/components/errormessage";
import ModalComponent from "./modal";
import { postCms } from "../classes/cmsApi";
import { Link } from "react-router-dom";
import { useCardData } from "../pages/card/card";
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { usr as user } from "../classes/user";

const VeikModal = ({ trigger }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState({});
  const { handleModalClose, modalSettings, language } = useContext(StateContext);
  const { props } = modalSettings;
  useCardData(language, props?.uuid, user); // data is re-fetched automatically when hook is called, can use refetch() to manually re-fetch

  const handleCopyClick = (valueToCopy) => {
    const tempInput = document.createElement("input");
    tempInput.value = valueToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    alert("API atslēga nokopēta!");
  };

  const subscribe = useCallback(async () => {
    if (modalSettings.modalOpen && modalSettings.activeModal === "veik") {
      setError(false);
      setLoading(true);

      try {
        const result = await postCms("api/v1/add/subscriber", { nid: props?.nid });

        if (result?.error) {
          setError(result?.error);
          setLoading(false);
          return;
        }

        setResponseData(result);
      } catch (error) {
        setError(error?.error ?? "Notika kļūda");
      }
      setLoading(false);
    }
  }, [props, modalSettings]);

  useEffect(() => {
    subscribe();
  }, [subscribe]);

  return (
    <ModalComponent
      id="veik"
      trigger={trigger}
      title={loading ? "" : error ? t("popupMessageSubsciptionFailed") : t("popupMessageSubsciptionSuccess")}
      className="veik-modal"
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="veik-modal-container">
          {error ? (
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <ErrorMessage text={language === "en" ? "Connection error, data fetching failed" : "Savienojuma kļūda, neizdevās ielādēt datus"} />
              <ReloadPageButton onClick={subscribe} title={language === "en" ? "Try again" : "Mēģināt vēlreiz"} />
            </div>
          ) : (
            <>
              <div className="modal-dialog-content-sub2">
                <p className="modal-dialog-content-sub2-font3" style={{ marginRight: 20 }}>
                  <Trans i18nKey="datasetAddAPIKey"></Trans>
                </p>

                <div className="api-container" onClick={() => handleCopyClick(responseData?.api)}>
                  <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{responseData?.api}</span>
                  <img
                    alt="alt"
                    style={{
                      width: 20,
                      height: 20,
                      fill: "dbdcdc",
                      cursor: "pointer",
                    }}
                    className="modal-dialog-content-sub2-font3-icon"
                    src="/assets/img/file.svg"
                  />
                </div>
              </div>
              <div className="modal-dialog-content-sub2-font3">
                <p>
                  {t("veikModalFollow")} 
                  <Link
                    target={"_blank"}
                    to="/abonetaskopas"
                    className="modal-dialog-content-sub2-font3-detail"
                  >
                    {t("veikModalSubscribedData")}
                  </Link>
                  {t("veikModalAnd")}
                  <Link target={"_blank"} to="/palidziba?currentPage=helpDN" className="modal-dialog-content-sub2-font3-detail">
                    {t("veikModalDocumentation")}
                  </Link>
                  {t("veikModalYourProfile")}
                </p>
              </div>
            </>
          )}
          <div className="modal-dialog-content-sub5">
            <button
              className="modal-dialog-content-btn"
              type="button"
              onClick={() => {
                handleModalClose("veik");
              }}
            >
              <span className="modal-dialog-content-btn-font"><Trans i18nKey="popupMessageClose"></Trans></span>
            </button>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

export default VeikModal;

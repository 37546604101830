import { useContext } from "react";
import { StateContext } from "../context";
import { Trans } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { usr } from "../classes/user";
import propTypes from "prop-types";

const subPageToOpen = (currentPage) => {

  currentPage = currentPage.replace(/\/lv|\/en/g, "");

  switch (currentPage) {
    case "/klusti":
      return "platformIntro";
    case "/about":
      return "platformOverview";
    case "/kontakti":
      return "platformContacts";
    case "/catalog":
      return usr.can("API dokumentācija (datu devējam)") ? "publishData" : "subAndManageData";
    case "/abonetaskopas":
      return "apiUseCases";
    case "/buj":
      return "reportAProblem";
    case "/apliecinajumi":
      return "certificates";
    default:
      return undefined;
  }
}

const helpPagePreOpen = (currentPage) => {
  const subPage = subPageToOpen(currentPage);
  if (subPage) {
    return `?subPage=${subPage}`;
  }
  if (!usr?.sub) return "";
  return usr.can("API dokumentācija (datu devējam)") ? "?currentPage=helpDD" : "?currentPage=helpDN";
};

const BannerPath = ({ path, pathUrl, renderArrowBefore = true }) => {
  if (!path) return null;

  const content = pathUrl ? (
    <Link style={{ textDecoration: "none" }} to={pathUrl}>
      {path.toUpperCase()}
    </Link>
  ) : (
    path.toUpperCase()
  );

  return (
    <>
      {renderArrowBefore && (
        <img alt="alt" className="catalog-header-left-directory-icon" src="/assets/img/arrow.png" />
      )}
      <div className="catalog-header-left-directory-step">{content}</div>
    </>
  );
};
BannerPath.propTypes = {
  path: propTypes.string,
  pathUrl: propTypes.string,
  renderArrowBefore: propTypes.bool,
};

const Banner = ({ bannerData, children, shouldCenterImg, id }) => {
  const navigate = useNavigate();
  const { language } = useContext(StateContext);
  const location = useLocation();
  const currentPage = location.pathname; // This will provide thee the current URL path

  let shouldSkipEasyRead = ["/apliecinajumi", "/vieglilasit", "/manaskopas/list"].some((path) =>
    currentPage.includes(path)
  );

  let shouldSkipHelpAndEasyToRead = [
    "/passwordcreate",
    "/passwordreset",
    "/dashboard",
    "/profile",
    "/abonetaskopas",
    "/messages",
    "/manaskopas/list",
  ].includes(currentPage);

  return (
    <div className="catalog-header" id={id}>
      <div className="catalog-header-left">
        <div
          className={
            shouldCenterImg
              ? "catalog-header-left-topic center-banner-title-img"
              : "catalog-header-left-topic"
          }
          title={bannerData.title}
        >
          {bannerData.title}
          {bannerData.img && <img alt="alt" className={bannerData.img.className} src={bannerData.img.href} />}
        </div>
        <div className="catalog-header-left-directory">
          <BannerPath path={bannerData.path1} pathUrl={bannerData.path1Url} renderArrowBefore={false} />
          <BannerPath path={bannerData.path2} pathUrl={bannerData.path2Url} />
          <BannerPath path={bannerData.path3} pathUrl={bannerData.path3Url} />
          <BannerPath path={bannerData.path4} pathUrl={bannerData.path4Url} />
        </div>
      </div>
      {children}
      {!shouldSkipHelpAndEasyToRead && (
        <div className="catalog-header-right">
          <div
            className="catalog-header-right-text1"
            onClick={() => navigate(`/palidziba${helpPagePreOpen(currentPage)}`)}
          >
            <img className="catalog-header-right-icon" alt="alt" src="/assets/img/book.svg" />
            <Trans i18nKey="pathingHelp"></Trans>
          </div>
          {!shouldSkipEasyRead && (
            <>
              <div className="catalog-header-right-flash">|</div>
              <div className="catalog-header-right-text2">
                <div onClick={() => navigate(`/${language}/vieglilasit`)}>
                  <Trans i18nKey="pathingEasyRead"></Trans>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Banner;

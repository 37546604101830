import { useState } from "react";
import Field from "../../../common/atoms/textfield";
import "../index.css";
import SecondaryButton from "../../../common/atoms/secondarybutton";
import EditProfile from "../../../modals/editprofilemodal";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CheckBox from "../../../common/checkbox";
import { fetch } from "../../../classes/auth";
import { BACKEND_URL } from "../../../classes/api";
import { t } from "i18next";
import { Trans } from 'react-i18next';
import { deleteCms } from "../../../classes/cmsApi";
import { usr as user } from "../../../classes/user";
import { countries } from "../../../countries";

const UserDataBoxes = () => {

  if (user.is("Datu ņēmējs")) {
    return (
      <div className="column">
        <h2>
          <Trans i18nKey="informationAboutTheUser"></Trans>
        </h2>
        <Field title={t("profileName")} text={user.profileName} />
        <Field title={t("userVeids")} text={t("registrationDataRecipient")} />
        <Field title={t("email")} text={user.email} />
        <br />
        <EditProfile
          type="DN"
          trigger={
            <div>
              <SecondaryButton title={t("generalFix")} svg={"pen"} />
            </div>
          }
        />
      </div>
    );
  } else if (user.is("Datu devējs")) {
    return (
      <>
        <div className="column">
          <h2>
            <Trans i18nKey="informationAboutTheOrganisation"></Trans>
          </h2>
          <Field title={t("registrationJuridicalName") + ": "} text={user.juridicalPersonName || "-"} />
          <Field
            title={t("registrationJuridicalRegNr") + ": "}
            text={user.juridicalPersonRegNr || "-"}
          />
          <Field title={t("country") + ": "} text={countries.find((c) => c.value === user.country)?.label || "-"} />
          <Field
            title={t("registrationJuridicalAddress") + ": "}
            text={user.juridicalPersonAddress || "-"}
          />
          <Field title={t("email") + ": "} text={user.secondEmail || "-"} />
          <a
            className="box-field user-website"
            href={user.website.includes("http") ? user.website : `http://${user.website}`}
            target="_blank"
            rel="noreferrer"
          >
            {user.website}
          </a>
          <br />
          <EditProfile
            type="DD_Juridical"
            trigger={
              <div>
                <SecondaryButton title={t("generalFix")} svg={"pen"} />{" "}
              </div>
            }
          />
        </div>
        <div className="column">
          <h2>
            <Trans i18nKey="generalContactperson"></Trans>
          </h2>
          <p className="my-profile-title">
            {`${user.givenName} ${user.familyName}`}
          </p>
          <Field title={t("email") + ": "} text={user.email} />
          <Field title={t("phone") + ": "} text={user.phoneNr} />
          <br />
          <EditProfile
            type="DD_Contact"
            trigger={
              <div>
                <SecondaryButton title={t("generalFix")} svg={"pen"} />
              </div>
            }
          />
        </div>
      </>
    );
  }
};

const Box = ({ setNotifications }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [reason, setReason] = useState(``);
  
  async function handleDeletion() {
    if (checked && reason.length >= 3) {
      try {
        const datasetsResponse = await deleteCms(`api/v1/datasets/delete`);
        if (datasetsResponse.message === "success") {
          if (datasetsResponse.canDeleteUser) {
            const response = await fetch(`${BACKEND_URL}/api/delete`, {
              method: `POST`,
              headers: {
                crossDomain: true,
                Accept: `application/json`,
                "Content-Type": `application/json`,
                // Authorization: auth.getToken()
              }
            });

            const _data = await response.json();
            if (_data.ok) {
              setNotifications([
                {
                  canClose: false,
                  backgroundColor: `#9e313a`,
                  text: `Uz tavu e-pastu ir nosūtīts e-pasts, kurā jāapstiprina konta dzēšana spiežot uz saites "Apstiprinu konta dzēšanu".`,
                  style: { width: `100%` },
                }
              ]);
              setOpen(false);
            } else {
              console.error(`Neizdevās saglabāt lietotāja datus`);
            }
          } else {
            setNotifications([
              {
                canClose: false,
                backgroundColor: `#9e313a`,
                text: `Jūsu profilam tika atrastas publicētas datu kopas. Pēc tam, kad datu administrators būs beidzis kopu dzēšanu, Jūsu profils tiks dzēsts!".`,
                style: { width: `100%` },
              }
            ]);
            setOpen(false);
          }
        }
      } catch (err) {
        if (err.name === "SyntaxError") {
          console.error("Unexpected api call return");
        } else {
          console.error(err);
        }
      }
    }
  }


  return (
    <div className="three-columns" style={{ marginTop: '20px' }}>
      <UserDataBoxes />
      <div className="column" style={{ alignSelf: "center" }}>
        <div
          onClick={() => navigate("/passwordcreate")}
          className="profile-main-left-linkform-link"
          style={{ textDecoration: "none", cursor: "pointer" }}
        >
          <img
            alt="alt"
            className="profile-main-left-linkform-link-icon-blue"
            src="/assets/img/next.svg"
          />
          <span className="profile-main-left-linkform-link-text">
            <Trans i18nKey="generalChangePassword"></Trans>
          </span>
        </div>
        <div
          onClick={() => setOpen(true)}
          className="profile-main-left-linkform-link"
          style={{ marginTop: 10, textDecoration: "none", cursor: "pointer" }}
        >
          <img
            alt="alt"
            className="profile-main-left-linkform-link-red-icon"
            src="/assets/img/bin.svg"
          />
          <span className="profile-main-left-linkform-link-red-text">
            <Trans i18nKey="generalDeleteProfile"></Trans>
          </span>
        </div>
        <Modal
          className={"authorize deleteprofile"}
          isOpen={open}
          overlayClassName="authoverlay"
          style={{ width: `50%` }}
        >
          <div className="authorize-header">
            <span className="authorize-header-title" style={{ fontSize: 30 }}>
              <Trans i18nKey="areYouSureYouWantToDeleteProfile" />
            </span>
            <a
              href="#close"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(false)}
            >
              <img
                alt="alt"
                className="authorize-header-icon"
                src="/assets/img/cancel4.svg"
              />
            </a>
          </div>
          <div className="authorize-main-left-btngroup">
            <textarea
              value={reason}
              onChange={e => setReason(e.target.value)}
              aria-disabled={true}
              className="overall-input-big"
              placeholder={t("generalEnterDeletionReason")}
            />
          </div>
          <CheckBox
            style={{ margin: "1em 0" }}
            checked={checked}
            onClick={() => setChecked(!checked)}
            text={t("generalIAgreeThatTheAccountWillNoLongerBeRecoverableAndIWantToDeleteIt")}
          />
          <div style={{ display: `flex`, width: `100%` }}>
            <button
              className={`btn-danger ${!checked || reason.length < 3 ? `btn-disabled` : ``}`}
              onClick={() => {
                handleDeletion();
              }}
            >
              <span className="authorize-main-left-reg-text"><Trans i18nKey="delete"></Trans></span>
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Box;

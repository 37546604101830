import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import VeikModal from "../../../modals/veikmodal";
import { StateContext } from "../../../context";
import { auth } from "../../../classes/auth";
import moment from "moment";
import DeleteModal from "../../../modals/deletemodal";
import { Trans } from "react-i18next";
import { usr as user } from "../../../classes/user";
import { t } from "i18next";

export const formatLatvianDate = (date) => {
  if (!date) return "";
  // if not convertable by moment
  if (moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY") === "Invalid date") return date;
  return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("DD.MM.YYYY HH:mm");
};

const DataHolder = ({ data, isPublic, isSubbed, refetch }) => {
  const { handleModalOpen, language } = useContext(StateContext);
  const navigate = useNavigate();
  const cantSubcribe = auth.isAuthorized() && !data?.field_files && !data?.field_download_url && data?.field_sipr_dataset_uid !== "1"

  const handleSubscribe = () => {
    if (auth.isAuthorized()) {
      handleModalOpen("veik", { nid: data?.nid, uuid: data?.uuid });
    } else handleModalOpen("auth");
  };

  return (
    <div className="card-content-left w3-col">
      <div className="card-content-left-box">
        <div className="card-content-left-owner">
          <span style={{ display: "flex"}}>
            <Trans i18nKey="datasetOwner"></Trans>
          </span>
          <img alt="alt" className="card-content-left-owner-mark" src="/assets/img/card-mark.png" />
          <div className="card-content-left-owner-elements">
            {/* <img className="data-set-icon" alt="alt" src="/assets/img/earth.svg" /> */}
            {/* field_content_category ? */}
            {/* <span>{`${
              language === "lv" ? data.field_dataset_description_lv : data.field_dataset_description
            }`}</span> */}
          </div>
          {data.field_dzesanas_datums && (
            <div className="card-content-left-owner-update-period">
              <img alt="alt" src="/assets/img/warn.svg" />
              <span className="card-content-left-owner-update-period-label">
                <Trans i18nKey="datasetDeletable"></Trans>{" "}
              </span>
              <span>
                <b>{formatLatvianDate(data.field_dzesanas_datums)}</b>
              </span>
            </div>
          )}
          <div className="card-content-left-owner-update-period">
            <img alt="alt" src="/assets/img/refresh.svg" />
            <span className="card-content-left-owner-update-period-label">
              <Trans i18nKey="datasetUpdateFreq"></Trans>{" "}
            </span>
            <span>
              <b>{data.field_update_frequency ?? ""}</b>
            </span>
          </div>
          <div className="card-content-left-owner-update-data">
            <span>
              <Trans i18nKey="datasetPublished"></Trans>{" "}
              <b>{data.created ? formatLatvianDate(data.created) : ""}</b>
            </span>
            {data.field_changed_date && (
              <span>
                <Trans i18nKey="datasetLastChanges"></Trans>{" "}
                <b>{formatLatvianDate(data.field_changed_date)}</b>
              </span>
            )}
            {data?.field_score && data.field_score !== "0" && (
              <span>
                <Trans i18nKey="datasetScore"></Trans> <b>{data.field_score}</b>
              </span>
            )}
          </div>
          {isPublic && !isSubbed && !user?.is("Datu devējs") && (
            <div className="card-content-left-owner-subscribe">
              <button
                className={`card-content-owner-subscribe-button ${cantSubcribe ? "disabled" : ""}`}
                onClick={handleSubscribe}
                disabled={cantSubcribe}
              >
                <img alt="alt" src="/assets/img/voice.svg" />
                <Trans i18nKey="datasetSubscribe"></Trans>
              </button>
              {cantSubcribe && <p className="cant-sub-text">{t("datasetCantSub")}</p>}
            </div>
          )}

          {isSubbed && (
            <div className="card-content-left-owner-unsubscribe">
              <button
                onClick={() => handleModalOpen("delete", { id: data?.nid, uuid: data?.uuid })}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img alt="alt" src="/assets/img/voice.svg" />
                <span>{t("unSubscribeLong")}</span>
              </button>
            </div>
          )}
          <DeleteModal modalTtile={t("unSubscribeLong")} refetch={refetch} buttonTitle={t("unSubscribe")} />
          <VeikModal />
        </div>
        <div className="card-content-back" onClick={() => navigate("/catalog")} style={{ cursor: "pointer" }}>
          <div className="card-content-back-content">
            <img alt="alt" src="/assets/img/next.svg" />
            <span>
              <b>
                <u className="card-content-back-content-text">
                  <Trans i18nKey="datasetToCatalog"></Trans>
                </u>
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataHolder;

import { useState } from "react";
import { Banner, NotificationBanner } from "../common";
import StepFive from "./register/components/stepfive";
import { useTranslation } from "react-i18next";
import { BACKEND_URL, post } from "../classes/api";
import { auth } from "../classes/auth";

const PasswordCreate = () => {
  const { t } = useTranslation();
  const [response, setResponse] = useState({
    ok: false,
    messageKey: "",
  });

  const bannerData = {
    title: t("registrationPassworCreation"),
    path1Url: "/",
    path1: t("workspaceStart"),
    path2: t("registrationPassworCreation"),
  };

  return (
    <>
      <Banner bannerData={bannerData} />
      {response.messageKey && (
        <NotificationBanner
          text={t(response.messageKey)}
          backgroundColor={response.ok ? "#33D751" : "#9e313a"}
          canClose={true}
        />
      )}
      {!response?.ok && (
        <StepFive
          onClick={async (data) => {
            const { oldPassword, password, password2 } = data;
            if (auth.isAuthorized()) {
              await post(
                `${BACKEND_URL}/api/changePassword`,
                JSON.stringify({ password: oldPassword, newPassword: password, newPassword2: password2 })
              )
                .then((resp) => {
                  setResponse({
                    ok: resp.data.ok,
                    messageKey: resp.data.message.error ? resp.data.message.error : resp.data.message.success,
                  });
                })
                .catch((err) => console.error(err));
            }
          }}
        />
      )}
    </>
  );
};

export default PasswordCreate;

import { useEffect, useState, useContext, useReducer } from "react";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/loader";
import { getCms } from "../../../classes/cmsApi";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { StateContext } from "../../../context";
import { usr } from "../../../classes/user";
import { auth } from "../../../classes/auth";
import { use } from "i18next";
import { DEFAULT_MAP_URL } from "../../../common/footer";

const NEXT = "NEXT";
const PREV = "PREV";
const JUMP = "JUMP";
const MOVE = "MOVE";
const tempTimeout = 20; // for the js to wait for the css transition to end
const initialState = { pos: 1, sliding: false, dir: NEXT };

function reducer(state, { type, pos, numItems }) {
  switch (type) {
    case NEXT:
      return {
        ...state,
        pos: (state.pos + 1) % numItems,
        sliding: true,
        dir: NEXT,
      };
    case PREV:
      return {
        ...state,
        pos: (state.pos - 1 + numItems) % numItems,
        sliding: true,
        dir: PREV,
      };
    case JUMP:
      return {
        ...state,
        pos,
        sliding: false,
      };
    case MOVE:
      if (pos === numItems) {
        return {
          ...state,
          pos: 0,
          sliding: true,
        };
      }
      return {
        ...state,
        pos,
        sliding: true,
      };
    default:
      return state;
  }
}

const SlideShow = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { language } = useContext(StateContext);
  const [numItems, setNumItems] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { isLoading, isError, data, error } = useQuery(
    ["slideshow", language],
    async () => {
      const _bData = await getCms("public/banner/data", language);
      const filteredData = _bData.filter((item) => {
        if (auth.isAuthorized()) {
          return (
            (usr.is("Datu ņēmējs") && item.field_slideshow_link_1 === "DN") ||
            (usr.is("Datu devējs") && item.field_slideshow_link_1 === "DD")
          );
        }
        return item.field_slideshow_link_1 === "";
      });
      if (filteredData.length > 1) {
        const firstItem = filteredData[0];
        const lastItem = filteredData[filteredData.length - 1];
        filteredData.unshift(lastItem);
        filteredData.push(firstItem);
      }
      return filteredData;
    },
    {
      refetchOnWindowFocus: false,
      // caching response by default for 5 minutes use cacheTime, to set a custom time
    }
  );

  const moveToPos = (pos) => {
    dispatch({ type: MOVE, pos });
  };

  const slide = (dir) => {

    if (isTransitioning) return;
    setIsTransitioning(true);

    if (dir === NEXT) {
      if (state.pos === numItems - 2) {
        dispatch({ type: JUMP, pos: 0, numItems });
      }
      setTimeout(() => {
        dispatch({ type: NEXT, numItems });
      }, tempTimeout);
    } else if (dir === PREV) {
      if (state.pos === 1) {
        dispatch({ type: JUMP, pos: numItems - 1, numItems });
      }
      setTimeout(() => {
        dispatch({ type: PREV, numItems });
      }, tempTimeout);
    }

    setTimeout(() => {
      setIsTransitioning(false);
    }, 600 + tempTimeout);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isTransitioning && numItems > 1) {
        if (state.pos === numItems - 2) {
          dispatch({ type: JUMP, pos: 0, numItems });
        }
        setTimeout(() => {
          dispatch({ type: NEXT, numItems });
        }, tempTimeout);
      }
    }, 5000);
    
    return () => clearInterval(interval);
  }, [state, isTransitioning, numItems]);
  

  useEffect(() => {
    if (data) {
      setNumItems(data.length);
    }
  }, [data]);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
  });

  if (isError) {
    console.error(error);
    return null;
  }
  if (isLoading) return <Loader />;
  if (!data) return null;

  return (
    <div {...handlers}>
      <div
        style={{
          width: "100%",
          height: "auto",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            transition: state.sliding ? "transform 0.6s ease-in-out" : "none",
            transform: `translateX(-${(state.pos + (numItems === 1 ? -1 : 0)) * 100}%)`,
          }}
        >
          {data.map((item, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                flex: "0 0 100%",
                cursor: "pointer",
                position: "relative",
                overflow: "hidden",
              }}
              onClick={() => {
                if (item.field_slideshow_link_1 === "internal") {
                  navigate(item.field_slideshow_link);
                } else {
                  window.open(item.field_slideshow_link, "_blank");
                }
              }}
            >
              <img
                src={item.field_slideshow_image}
                alt={`Slide ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {data.length > 1 && (
        <div className="slidebar-dots-container">
          <div className="slidebar-slideshow-prev" onClick={() => slide(PREV)}>
            &#10094;
          </div>
          <div className="slidebar-slideshow-dots">
            {data.map((item, index) => {
              if (index !== 0 && index !== data.length - 1) {
                return (
                  <span
                    key={index}
                    onClick={() => {
                      moveToPos(index);
                    }}
                    className={`slidebar-slideshow-dots-dot${index === state.pos ? " active-dot" : ""}`}
                  ></span>
                );
              }
              return null;
            })}
          </div>
          <div className="slidebar-slideshow-next" onClick={() => slide(NEXT)}>
            &#10095;
          </div>
        </div>
      )}
    </div>
  );
};

const defaultStatistics = { device_count: "-", total_count: "-", key_count: "-" };

const SlideBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useContext(StateContext);

  const { data: statistics } = useQuery(
    ["statistics"],
    async () => {
      try {
        return await getCms("public/statistics-block");
      } catch (error) {
        console.error("Error fetching statistics", error);
        return defaultStatistics;
      }
    },
    {
      placeholderData: defaultStatistics,
      refetchOnWindowFocus: false,
      staleTime: 10000, // cache for 10 minutes
    }
  );

  return (
    <>
      <div className="slidebar">
        <div className="slidebar-slideshow">
          <SlideShow />
        </div>
        <a
          rel="noreferrer"
          className="slidebar-map-container w3-col"
          href={DEFAULT_MAP_URL + language}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <img alt="alt" src={t("mapAssetLink")} style={{ height: "auto" }} />
        </a>
      </div>
      <div className="state">
        <div className="state-transfer">
          <div className="state-transfer-header">
            <div className="pulse-animation">
              <img alt="alt" className="state-transfer-header-dot" src="/assets/img/state.png" />
            </div>
            <div
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              className="state-transfer-header-numberdot"
            >
              {statistics.device_count}
            </div>
          </div>
          <div className="state-transfer-context">
            <span className="state-transfer-context-text">
              <Trans i18nKey="realTimeDataStart"></Trans>
              <br />
              <Trans i18nKey="realTimeDataEnd"></Trans>
            </span>
          </div>
        </div>
        <div className="state-transfer">
          <div className="state-transfer-header">
            <div
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              className="state-transfer-header-number"
            >
              {statistics.total_count}
            </div>
          </div>
          <div className="state-transfer-context">
            <span className="state-transfer-context-text">
              <Trans i18nKey="dataInstancesStart"></Trans>
              <br />
              <Trans i18nKey="dataInstancesEnd"></Trans>
            </span>
          </div>
        </div>
        <div className="state-transfer">
          <div className="state-transfer-header">
            <div
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              className="state-transfer-header-number"
            >
              {statistics.key_count}
            </div>
          </div>
          <div className="state-transfer-context">
            <span className="state-transfer-context-text">
              <Trans i18nKey="dataSetsCountStart"></Trans>
              <br />
              <Trans i18nKey="dataSetsCountEnd"></Trans>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlideBar;

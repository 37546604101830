import React from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import "./index.css";
import ReportModal from "../../modals/reportmodal";
import { auth } from "../../classes/auth";
import { useContext } from "react";
import { StateContext } from "../../context";

const Error = ({
  code = 404,
  message = t("weDontHaveThisPage"),
  image = "errorguy", // errorguy errorguy2
}) => {
  const { handleModalOpen } = useContext(StateContext);
  let { lang, uri } = useParams();

  return (
    <div className="page-error-container">
      <span className="page-error-code"> {code} </span>
      <span className="page-error-message">{message} </span>
      <img alt="alt" src={`/assets/img/${image}.png`} />
      {code === 404 && (
        <span className="page-error-message light page-error-message-bottom">
          {t("errorPage1")}
          <div style={{ marginBottom: "60px" }} className="page-error-message-modal-container">
            {t("aboutErrorYouCanStart")}
            {auth.isAuthorized() ? (
              <>
                <ReportModal
                  trigger={
                    <span className="page-error-message light blue">{t("aboutErrorYouCanReport")}</span>
                  }
                />
                .
              </>
            ) : (
              <>
                <span
                  className="page-error-message light blue"
                  onClick={() => handleModalOpen("auth", { redirectTo: lang + "/" + uri })}
                >
                  {t("aboutErrorYouCanReport")}
                </span>
                .
              </>
            )}
          </div>
        </span>
      )}
      {code === 503 && (
        <span className="page-error-message light">
          {t("pleaseWaitAWhile")}
          <br />
          {t("soonWillWorkAgain")}
        </span>
      )}
    </div>
  );
};

export default Error;

import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StateContext } from "../context";
import NavbarData from "./components/navbar_data";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { SearchButton } from "./SearchButton";

const NavBar = () => {
  const { fontSize } = useContext(StateContext);
  const { navbarOpened } = useContext(StateContext);
  const navigate = useNavigate();

  return (
    <nav className="navbar" style={{ height: parseInt(fontSize) - 30 }}>
      <div className="navbar-container">
        <div className="navbar-menus" style={{ left: navbarOpened ? 0 : 9999 }}>
          <NavbarData />
        </div>
        <SearchBar navigate={navigate} />
      </div>
    </nav>
  );
};

const SearchInput = ({ placeholder, fontSize, includeRadioButtons = false, navigate }) => {
  const [, setParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const handleSearch = () => {
    if (searchText.length < 3) {
      setErrorMessage("minInputLength");
      return;
    }
    const tmpSearchText = searchText;
    setSearchText("");
    setErrorMessage("");

    if (selectedValue === 2) {
      if (window.location.pathname === "/catalog") {
        setParams({ datasetName: tmpSearchText });
      } else {
        navigate(`/catalog?datasetName=${tmpSearchText}`);
      }
    }
    if (selectedValue === 1) {
      if (window.location.pathname === "/search") {
        setParams({ searchValue: tmpSearchText, page: 0 });
      } else {
        navigate(`/search?searchValue=${tmpSearchText}&page=0`);
      }
    }
  };
  return (
    <div className={includeRadioButtons ? "searchbar-box" : "searchbar-box-closed"}>
      <div className={`searchbar-box-input-container ${errorMessage ? "red-outline" : ""}`}>
        <input
          type="text"
          className="search-modal-input-hovered"
          placeholder={placeholder}
          style={{ minHeight: parseInt(fontSize) * 0.5 + "px" }}
          value={searchText}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
            if (e.target.value.length === 0) {
              setErrorMessage("");
            }
          }}
        />
        <div className="navbar-searchbar-icon">
          <SearchButton
            className={errorMessage ? "c-error-stroke" : ""}
            hasText={!!searchText}
            onClick={() => {
              setSearchText("");
              setErrorMessage("");
            }}
          />
        </div>
      </div>
      {includeRadioButtons && (
        <>
          {errorMessage && <span className="red-error-message">{t(errorMessage)}</span>}
          <div className="radio-container">
            <div
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              id="searchbox1"
              onClick={() => setSelectedValue(1)}
            >
              <input
                className="radio-item"
                type="radio"
                value="1"
                name="group1"
                checked={selectedValue === 1}
                readOnly
              />
              <div className="radio-text">
                <Trans i18nKey="allPlatform"></Trans>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              onClick={() => setSelectedValue(2)}
            >
              <input
                className="radio-item"
                type="radio"
                value="2"
                name="group1"
                checked={selectedValue === 2}
                readOnly
              />
              <div className="radio-text">
                <Trans i18nKey="inDataCatalog"></Trans>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className="search-modal-button"
                onClick={() => handleSearch()}
                style={{ minHeight: 25 + parseInt(fontSize) / 10, minWidth: parseInt(fontSize) }}
              >
                <Trans i18nKey="search"></Trans>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SearchBar = ({ handleKeyDown, navigate }) => {
  const { fontSize } = useContext(StateContext);
  const { navbarOpened, setNavBarOpened } = useContext(StateContext);
  const [hovered, setIsHovered] = React.useState(false);
  const { t } = useTranslation();

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  return (
    <>
      <div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} className="searchContainer">
        <SearchInput
          handleKeyDown={handleKeyDown}
          placeholder={t("searchPlaceHolder")}
          fontSize={fontSize}
          includeRadioButtons={hovered}
          navigate={navigate}
        />
      </div>
      <button onClick={() => setNavBarOpened(!navbarOpened)} className="navbar-search-burger"></button>
    </>
  );
};

export default NavBar;

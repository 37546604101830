import Modal from "react-modal";
import { useContext, useMemo } from "react";
import { StateContext } from "../context";
import { TermsOfUse } from "../pages/register/components/termsOfUse";
import { useState } from "react";
import "../../global-styles.css";
import { t } from "i18next";
import { Trans } from "react-i18next";
import PrimaryButton from "../common/atoms/primarybutton";
import NotificationBanner from "../common/notification";
import { useNavigate } from "react-router-dom";
import { fetch } from "../classes/auth";
import { usr } from "../classes/user";

const ButtonCancel = ({ onClick, title, style }) => {
  return (
    <button className="updated-rules-log-out" type="button" onClick={onClick} style={{ ...style }}>
      <p>
        <Trans i18nKey={title}></Trans>
      </p>
      <img
        alt="alt"
        src="/assets/img/Cancel.svg"
        style={{
          width: "1.5rem",
          height: "1.5rem",
        }}
      />
    </button>
  );
};

export const SiteRulesUpdatedModal = () => {
  const { updatedSiteRules, checkUpdatedSiteRules,setUpdatedSiteRules, fontSize } = useContext(StateContext);
  const [rulesAccepted, setRulesAccepted] = useState(false);
  const navigate = useNavigate();

  const fontSizeMultiplier = useMemo(() => Number(fontSize) / 100, [fontSize]);

  return (
    <Modal
      isOpen={updatedSiteRules}
      overlayClassName="authoverlay"
      className={"tos-modal"}
    >
      <div
        className={`font-size-adjust tos-div`}
        style={{ "--font-size-multiplier": fontSizeMultiplier, width: "100%" }}
      >
        <div className="authorize-header" style={{ marginBottom: 50 }}>
          <span className="authorize-header-title">
            <Trans i18nKey="updatedTOS" />
          </span>
        </div>
        <TermsOfUse className={"rules-scrollbar"} setAccepted={setRulesAccepted} />
        <div style={{marginTop: "2rem"}}>
          {!rulesAccepted && (
            <NotificationBanner
              text={t("registrationReadEntire")}
              backgroundColor="#56595a"
              canClose={true}
              style={{margin: 0}}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: "3rem",
            justifyContent: "flex-end",
            padding: "1rem 0",
          }}
        >
          <ButtonCancel
            title={t("workspaceLogout")}
            onClick={() => navigate("/logout")}
            style={{
              height: "4rem",
            }}
          />
          <PrimaryButton
            disabled={!rulesAccepted}
            title={t("acceptUpdatedTOS")}
            style={{
              height: "4rem",
            }}
            onClick={async () => {
              if (usr) usr.agreedToTermsOfService = true;
              await fetch(`/api/v1/fetch/update-user-tos`, { method: 'POST' });
              setUpdatedSiteRules(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

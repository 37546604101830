import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getCms, postCms } from "../../classes/cmsApi";
import { Banner } from "../../common";
import DataHolder from "./components/dataholder";
import InfoBox from "./components/infobox";
import { StateContext } from "../../context";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../common/loader";
import { t } from "i18next";
import { usr as user } from "../../classes/user";
import { unquotify } from "../search/search";

export const translateValue = (field) => {
  if (!field) return "";

  const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/; // ISO 8601 date
  const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i; // UUIDs

  if (dateRegex.test(field) || uuidRegex.test(field) || !isNaN(parseFloat(field))) {
    return field;
  }
  const fieldToTranslate = "datasetCardValue" + field.replace(/\s/g, "").replace(/[/()[\],.-:]/g, "");
  const translation = t(fieldToTranslate);
  return translation === fieldToTranslate ? field : translation;
};

const fetchCardData = async (language, id, user, hasPostedView) => {
  const returnData = { cardData: {}, isSubbed: false };
  // Izgūst metadatu datus no CMS
  const rawData = await getCms("public/metadata/data", language, { uuid: id ?? "" });
  if (rawData?.error) {
    console.error(rawData.error);
    throw new Error(rawData.error);
  }
  returnData.cardData = rawData[0] ?? {};

  if (!hasPostedView.current && rawData?.[0]?.nid) {
    try {
      postCms("api/v1/add/view", { nid: rawData[0].nid });
    } catch (e) {
    }
      hasPostedView.current = true;
  }

  // Atjauno lietotāja informāciju un pārbauda lietotāja abonēto datu kopu sarakstu
  await user?.refresh();
  if (user?.can("Datu ņēmēja abonētās  datu kopas")) {
    
    const userSubbed = await getCms("api/v1/subcribed/check/" + rawData[0].nid);
    if (!userSubbed.error) {
      returnData.isSubbed = userSubbed.subscribed;
      returnData.apiKey = userSubbed.api_key;
    }
  }
  return returnData;
};

export const useCardData = (language, id, user) => {
  const hasPostedView = useRef(false);

  const { data, isLoading, isError, error, refetch } = useQuery(
    [`card`, id],
    async () => {
      return await fetchCardData(language, id, user, hasPostedView);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return { data, isLoading, isError, error, refetch };
};

const skipTranslationKeys = ["field_language_content"];

const Card = () => {
  const { language } = useContext(StateContext);
  const { id } = useParams();
  const [translatedCardData, setTranslatedCardData] = useState({});

  const { data, isLoading, isError, error, refetch } = useCardData(language, id, user);

  useEffect(() => {
    // scrolls to top of the page when component is mounted for components that are pre-loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!data) return;
    const translated = Object.keys(data.cardData).reduce((acc, key) => {
      if (skipTranslationKeys.includes(key)) {
        acc[key] = data.cardData[key];
        return acc;
      }
      const translatedValue = translateValue(data.cardData[key]);
      const unquotedValue = unquotify(translatedValue);
      acc[key] = unquotedValue;
      return acc;
    }, {});
  
    setTranslatedCardData(translated);
  }, [language, data]);
  

  if (isLoading) return <Loader />;

  const { isSubbed, apiKey } = data ?? {isSubbed: false, apiKey: "" };
  
  if (!translatedCardData || Object.keys(translatedCardData).length === 0) {
    return <div>No data</div>;
  }
  if (isError) {
    console.error(error);
    return null;
  }

  const bannerData = {
    title: language === "lv" ? translatedCardData.field_dataset_name_lv : translatedCardData.field_dataset_name,
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("headerDataCatalog"),
    path2Url: "/catalog",
  };

  return (
    <>
      <Banner bannerData={bannerData} />
      <div className="card">
        <div className="card-content w3-row">
          <DataHolder
            data={translatedCardData}
            isPublic={translatedCardData.field_data_set_statuss === "Publicēta"}
            isSubbed={isSubbed}
            refetch={refetch}
          />
          <div className="card-content-detail w3-rest">
            <InfoBox 
              data={translatedCardData} 
              apiKey={apiKey} 
              isSubbed={isSubbed}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;

import React from "react";

const SecondaryButton = ({
  svg = "Cancel",
  title,
  invert = false,
  rotate = "0deg",
  big,
  onClick,
  style,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`default-secondary-button${svg === "Cancel" ? " default-secondary-button-cancel" : ""}`}
      style={{
        alignItems: "center",
        justifyContent: invert ? "space-between" : "center",
        flexDirection: invert ? "row-reverse" : "row",
        gap: "1em",
        borderRadius: 5,
        height: big ? 64 : 43,
        paddingLeft: big ? 25 : invert ? 12 : 0,
        ...style,
      }}
      type="button"
    >
      <p className={big ? "default-secondary-button-text-big" : "default-secondary-button-text"}>{title}</p>
      {svg !== "none" && (
        <img
          alt="alt"
          className={`default-secondary-button-${
            svg === "Cancel" ? "cancel" : svg === "add" ? "add" : "edit"
          }-icon${big ? "-big" : ""}`}
          src={`/assets/img/${svg}.svg`}
          style={{ margin: 0, rotate }}
        />
      )}
    </button>
  );
};

export default SecondaryButton;

import { Trans, useTranslation } from "react-i18next";
import { AbonetasKopasTableRow } from "../manaskopas/components/tables/AbonetasKopasTable";
import { useDataSetsData } from "../manaskopas/manaskopas";
import Loader from "../../common/loader";
import { ErrorMessage, ReloadPageButton } from "../manaskopas/components/errormessage";
import PropTypes from "prop-types";

const TableRow = ({ item }) => {
  return (
    <AbonetasKopasTableRow
      item={item}
      col1={
        <td className="manaskopas-td-container">
          <div className="manaskopas-main-tb1-tr2-td1">
            <span className="manaskopas-main-tb1-tr2-td1-font">{item?.dataset_name}</span>
            <img alt="alt" className="manaskopas-main-tb1-tr2-td1-warm1" src="/assets/img/link.svg" />
          </div>
        </td>
      }
      col2={
        <td>
          <div className="manaskopas-main-tb1-tr2-td2">
            <b>{item?.download_count | 0}</b>
          </div>
        </td>
      }
      col3={<></>}
    />
  );
};
TableRow.propTypes = {
  item: PropTypes.shape({
    dataset_name: PropTypes.string,
    download_count: PropTypes.number,
  }),
};

const ReportsPageTable = () => {
  const { data, isLoading, isError } = useDataSetsData();
  const { t } = useTranslation();

  if (isLoading) return <Loader />;
  if (isError) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <ErrorMessage text={t("failedToLoadDS")} />
        <ReloadPageButton style={{ marginTop: 16 }} />
      </div>
    );
  }

  const activeDatasets = data?.active || [];
  return (
    <>
      {data.title && (
        <div className="manaskopas-main-sub3">
          <span className="manaskopas-main-sub3-font">
            <b>{data.title}</b>
          </span>
        </div>
      )}

      <div style={{ overflowX: "auto", borderRadius: 4 }}>
        <table className="manaskopas-main-tb1" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr className="manaskopas-main-tb1-tr1">
              <th className="manaskopas-main-tb1-tr1-th1">
                <span className="manaskopas-main-tb1-tr1-th1-font1">
                  <Trans i18nKey="dataSet"></Trans>
                </span>
              </th>
              <th>
                <span className="manaskopas-main-tb1-tr1-th1-font1">
                  <Trans i18nKey="downloadAmount"></Trans>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {activeDatasets.map((item, index) => {
              return <TableRow item={item} key={item?.uuid || index} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReportsPageTable;

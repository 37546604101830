import { useContext } from "react";
import Modal from "react-modal";
import { StateContext } from "../context";

const ModalComponent = ({ id, trigger, children, title, className, onClose, show }) => {
  const { handleModalOpen, handleModalClose, modalSettings, fontSize } = useContext(StateContext);
  const fontSizeMultiplier = 1 + (Number(fontSize) - 100) / 100;

  return (
    <>
      <div onClick={() => handleModalOpen(id)}>{trigger}</div>
      {(id === modalSettings.activeModal || show) && (
        <Modal
          className={className ?? "authorize"}
          isOpen={modalSettings.modalOpen}
          onRequestClose={() => {
            handleModalClose(id);
            onClose?.();
          }}
          overlayClassName="authoverlay"
          style={{ "--font-size-multiplier": fontSizeMultiplier }}
        >
          <div
            className={`font-size-adjust ${show === "eddModal" ? "eddcss" : ""}`}
            style={{ "--font-size-multiplier": fontSizeMultiplier }}
          >
            {title && (
              <div className="authorize-header">
                <span className="authorize-header-title">{title}</span>
                <a
                  href="#close"
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    handleModalClose(id);
                    onClose?.();
                  }}
                >
                  <img alt="alt" className="authorize-header-icon" src="/assets/img/cancel4.svg" />
                </a>
              </div>
            )}
            {children}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalComponent;
